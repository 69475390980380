import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, IconButton, Modal, Box, Button, Typography, Tooltip, tooltipClasses } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TableDropdown from "../Dropdown/TableDropdown";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../../services/http.service";
import StatusUpdate from "../Modal/StatusModel";
import GetAppIcon from "@mui/icons-material/GetApp";
import useFileActions from "../../hooks/useFileActions";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import styled from "styled-components";
import { theme } from "../../constant/Theme";

const showErrorToastMessage = (msg) => {
  toast.error(msg, {
    position: "bottom-right",
  });
};
const showSuccessToastMessage = (msg) => {
  toast.success(msg, {
    position: "bottom-right",
  });
};
export default function BasicTable({
  data,
  headingTable,
  status,
  resetPassword,
  resetPasswordUrl,
  valuesKeys,
  editPageUrl,
  statusActiveUrl,
  viewPageUrl,
  deleteUrl,
  isDelete,
  isDownload,
  onClickDownload,
  viewMatterUrl,
}) {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const noUsersCount = useSelector((state) => state?.reducer?.user?.subscriptionPlan?.noUsers);
  const { handleFileAction, FileViewModal } = useFileActions();
  // here is active state state
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = React.useState(null);
  const { setBearerToken, post } = useAxios();
  const queryClient = useQueryClient();
  const showWarningsToastMessage = (msg) => {
    return toast.warning(msg, {
      position: "bottom-right",
    });
  };
  const setActiveData = async (newData) => {
    setBearerToken(token);
    const response = await post(statusActiveUrl, newData);
    return response.data;
  };
  const user = useSelector((state) => state?.reducer?.user);

  const mutation = useMutation(setActiveData, {
    onSuccess: () => {
      showSuccessToastMessage("The  status has been updated successfully.");
      if (statusActiveUrl == "/caseStatus") {
        queryClient.invalidateQueries("caseData");
        queryClient.invalidateQueries("caseDropdown");
      } else if (statusActiveUrl == "/matterStatus") {
        queryClient.invalidateQueries("matterData");
      } else {
        queryClient.invalidateQueries("userData");
      }
    },
    onError: () => {},
  });
  useEffect(() => {
    if (mutation?.error?.response?.data?.userLimitError) {
      showWarningsToastMessage("User limit exceeded. To activate more users, please consider upgrading to a different subscription plan.");
    } else if (mutation?.error?.response) {
      showErrorToastMessage("Something was wrong!");
    }
  }, [mutation?.error?.response?.data?.userLimitError]);

  const handleStatusActive = (row) => {
    setCurrentRow(row);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    if (currentRow) {
      let data;
      if (statusActiveUrl == "/adminInviterUserActive") {
        data = {
          id: currentRow._id,
          status: !currentRow.active,
          noUsersCount: noUsersCount,
        };
      } else {
        data = {
          id: currentRow._id,
          status: !currentRow.active,
        };
      }
      mutation.mutate(data);
    }
    handleClose();
  };
  const getDocumentUrl = (row) => {
    const documentPaths = {
      medicalRecordFile: "uploads/admin/medicalFiles/",
      exhibitFile: "uploads/admin/exhibitFiles/",
      discoveryFile: "uploads/admin/discoveryFiles/",
      depositionFile: "uploads/admin/depositionFiles/",
      correspondenceFile: "uploads/admin/correspondenceFiles/",
      pleadingsFile: "uploads/admin/pleadingsFiles/",
    };

    for (const key in documentPaths) {
      if (row[key] && row[key].trim() !== "" && row[key] !== "N/A") {
        return `${documentPaths[key]}${row[key]}`;
      }
    }

    return null;
  };
  const documentKeys = ["medicalRecordFile", "exhibitFile", "discoveryFile", "depositionFile", "correspondenceFile", "pleadingsFile"];
  const getValueTable = (row, column) => {
    const value = row[column];
    if (documentKeys.includes(column)) {
      if (value && value.trim() !== "" && value !== "N/A") {
        // return value.split("_")[1] ?? value?.substring(2, 4);
        const fileName = value.split("_")[1] ?? value;
        return fileName.length > 4 ? fileName.substring(0, 15) + "..." : fileName;
      } else {
        return "N/A";
      }
    }
    // if (column === "address") {
    //   const address = row["address"]?.trim();
    //   const city = row["city"]?.trim();
    //   const state = row["state"]?.trim();

    //   if (!address && !city && !state) {
    //     return "N/A";
    //   }

    //   return `${address}, ${city}, ${state}`;
    // }
    if (column === "address") {
      const address = row["address"]?.trim();
      const city = row["city"]?.trim();
      const state = row["state"]?.trim();

      if (!address && !city && !state) {
        return "N/A"; // If all fields are empty, return "N/A"
      }

      // Build the address string dynamically, adding commas only if the previous field exists
      const fullAddress = [
        address, // Only include the address if it's not empty
        city && address ? `, ${city}` : city, // Add a comma before the city only if address exists
        state && (city || address) ? `, ${state}` : state, // Add a comma before the state only if city or address exists
      ]
        .filter(Boolean)
        .join(""); // Filter out empty values and join the string

      return fullAddress;
    }

    if (value == "" || value == "N/A") {
      return "N/A";
    }
    return value;
  };

  const getStatusStyle = (status) => {
    return {
      color: status === "pending" ? "rgb(255, 165, 0)" : status === "failed" ? "rgb(255, 0, 0)" : "rgb(113, 221, 55)",
      bgcolor: status === "pending" ? "rgba(255, 165, 0, 0.16)" : status === "failed" ? "rgba(255, 0, 0, 0.16)" : "rgba(113, 221, 55, 0.16)",
      textAlign: "center",
      borderRadius: "5px",
    };
  };

  const renderCellContent = (row, column) => {
    const value = getValueTable(row, column);
    if (column === "status") {
      return <Typography sx={getStatusStyle(value)}>{value === "pending" ? "Pending" : value === "failed" ? "Failed" : value === "generated" ? "Generated" : "Accepted"}</Typography>;
    }
    return <>{value}</>;
  };

  const dataCopy = data ? JSON.parse(JSON.stringify(data)) : [];
  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4A4C64",
      color: theme?.palette?.primary?.main,
      fontSize: "16px",
      height: "auto",
      width: "64rem",
      textAlign: "center",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
      // maxHeight: "250px", // Set max height for the tooltip content
      // overflowY: "auto", // Enable vertical scrolling
      // boxSizing: "border-box", // Ensure padding doesn't affect size
      // padding: "8px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#4A4C64",
    },
  }));

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        borderRadius: 0,
        marginTop: "30px",
        background: "none",
        // "&::-webkit-scrollbar": {
        //   display: "none",
        // },
        "&::-webkit-scrollbar": {
          height: "3px",
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "darkgrey",
          outline: `1px solid slategrey`,
        },
      }}
    >
      <Table
        sx={{
          minWidth: 650,

          "& .MuiTableCell-root": {
            backgroundColor: theme?.palette?.background?.paper,
            fontFamily: "Poppins, sans-serif",
            borderBottom: "1px solid #4A4C64",
          },
          "& .MuiTableHead-root": {
            borderBottom: "1px solid #4A4C64",
            borderTop: "1px solid #4A4C64",
          },
        }}
        aria-label="simple table"
      >
        <TableHead
          sx={{
            whiteSpace: "nowrap",
            wordBreak: "break-word",
            minWidth: 500,
            color: theme?.palette?.secondary?.main,
            "& .MuiTableCell-root": {
              padding: "12px 10px",
            },
          }}
        >
          <TableRow sx={{ border: "none !important" }}>
            {headingTable.map((column) => (
              <TableCell key={column} sx={{ color: theme?.palette?.secondary?.main }}>
                {" "}
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataCopy?.map((row, i) => (
            <TableRow key={i}>
              {valuesKeys.map((column) => (
                <TableCell
                  key={`${row._id}-${column}`}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    wordBreak: "break-word",
                    maxWidth: 250,
                    color: theme?.palette?.primary?.main,
                    padding: "16px 10px",
                    border: "2px soild red",
                  }}
                  align="left"
                >
                  {column === "cases" ? (
                    row.cases && row.cases.length > 1 ? (
                      // Show tooltip only when there are multiple cases
                      <CustomTooltip
                        title={
                          <Box
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                              backgroundColor: theme?.palette?.secondary?.inputColor,
                              color: theme?.palette?.secondary?.main,
                              cursor: "pointer",
                              whiteSpace: "pre-line",
                              overflowY: "auto",
                              maxHeight: "200px", // Limit height for better UX
                            }}
                          >
                            {row.cases.slice(0, 3).map((caseItem, index) => (
                              <Box
                                key={index}
                                sx={{
                                  borderBottom: "1px solid #67687E",
                                  paddingBottom: "4px",
                                  marginBottom: "4px",
                                  paddingTop: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                {caseItem}
                              </Box>
                            ))}
                            {row.cases.length > 3 && (
                              <Box
                                sx={{
                                  paddingTop: "4px",
                                  color: theme?.palette?.primary?.heading,
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => navigate("/persons/viewPersons", { state: { viewRecord: row } })}
                              >
                                Show More
                              </Box>
                            )}
                          </Box>
                        }
                        placement="top"
                        arrow
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#4A4C64",
                            color: theme?.palette?.secondary?.main,
                            whiteSpace: "pre-line",
                            padding: "8px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#4A4C64",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            border: "2px solid #4A4C64",
                            padding: "5px",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          {`${row.cases.length} Matters`}
                        </Box>
                      </CustomTooltip>
                    ) : (
                      // Directly show case name if there's only one case
                      <Box
                        sx={{
                          textAlign: "left",
                          padding: "5px",
                        }}
                      >
                        {row.cases[0] || "No cases"}
                      </Box>
                    )
                  ) : column === "clients" ? (
                    <CustomTooltip
                      title={
                        Array.isArray(row.clients) && row.clients.length > 0 ? (
                          <Box
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              backgroundColor: "#4A4C64",
                              cursor: "pointer",
                              color: theme?.palette?.secondary?.main,
                              whiteSpace: "pre-line",
                              overflowY: "auto",
                            }}
                          >
                            {row.clients.map((client, index) => (
                              <Box
                                key={index}
                                sx={{
                                  borderBottom: "1px solid #67687E",
                                  paddingBottom: "4px",
                                  marginBottom: "4px",
                                  paddingTop: "4px",
                                }}
                              >
                                {client}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Box
                            component="div"
                            sx={{
                              borderRadius: "4px",
                              padding: "8px",
                              backgroundColor: "#4A4C64",
                              color: theme?.palette?.secondary?.main,
                              maxWidth: "200px",
                            }}
                          >
                            No clients
                          </Box>
                        )
                      }
                      placement="top"
                      arrow
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#4A4C64",
                          color: theme?.palette?.secondary?.main,
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#4A4C64",
                        },
                      }}
                    >
                      <Box>
                        {row.clients && row.clients.length > 1 ? (
                          <Box
                            sx={{
                              width: "80%",
                              textAlign: "center",
                              border: "2px solid #4A4C64",
                              padding: "5px",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                            }}
                          >
                            {`${row.clients.length} Clients`}
                          </Box>
                        ) : (
                          row.clients[0] || "No clients"
                        )}
                      </Box>
                    </CustomTooltip>
                  ) : typeof row[column] === "boolean" && status ? (
                    <Switch
                      checked={row[column]}
                      onChange={() => handleStatusActive(row)}
                      sx={{
                        "& .MuiSwitch-track": { backgroundColor: theme?.palette?.secondary?.main },
                      }}
                    />
                  ) : documentKeys.includes(column) ? (
                    getValueTable(row, column) !== "N/A" ? (
                      <Box sx={{ display: "flex", gap: "16px" }}>
                        <Typography
                          onClick={() => handleFileAction(row.fileUrl, "view")}
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            cursor: "pointer",
                            color: "#686DFC",
                          }}
                        >
                          <RemoveRedEyeIcon sx={{ width: "20px", color: theme?.palette?.primary?.main }} />
                        </Typography>
                        <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(row.fileUrl, "download")}>
                          <GetAppIcon sx={{ width: "20px", color: theme?.palette?.primary?.main }} />
                        </Typography>
                        <FileViewModal />
                      </Box>
                    ) : (
                      getValueTable(row, column)
                    )
                  ) : (
                    renderCellContent(row, column)
                  )}
                </TableCell>
              ))}
              <StatusUpdate open={modalOpen} onClose={handleClose} onconfirm={handleConfirm} />
              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <TableDropdown
                    data={row}
                    viewPageUrl={viewPageUrl}
                    resetPassword={resetPassword}
                    resetPasswordUrl={resetPasswordUrl}
                    deleteUrl={deleteUrl}
                    isDelete={isDelete}
                    isDocument={getDocumentUrl(row)}
                    editPageUrl={editPageUrl}
                    isDownload={isDownload}
                    viewMatterUrl={viewMatterUrl}
                    onClickDownload={onClickDownload}
                  />
                  {/* <IconButton onClick={() => navigate(editPageUrl, { state: { userData: row } })} sx={{ color: "lightgray" }}>
                    <BiSolidEditAlt size={20} />
                  </IconButton> */}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
