import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

// inputIssueStyles.js
export const inputIssueStyles = {
  formControl: (formik) => ({
    width: "100%",
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
  }),
  summaryLaw: (formik) => ({
    width: "100%",
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
      "& .MuiInputBase-input": {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "none", // Firefox
      },
    },
  }),
  caseOfCitationOne: (formik) => ({
    width: "100%",
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
  }),
  factOne: {
    width: "100%",
    mb: "15px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
  },
  lawOne: {
    width: "100%",
    mb: "15px",

    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
  },
  rullingOne: {
    width: "100%",
    mb: "15px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
  },
  caseTwoCitation: (formik) => ({
    width: "100%",
    mb: "20px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
  }),
  caseThreeCitation: (formik) => ({
    width: "100%",
    mb: "15px",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
  }),

  viewHeading: {
    color: theme?.palette?.primary?.secondaryheading,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    cursor: "pointer",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    marginTop: "6px",
    cursor: "pointer",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
  },
  viewSummaryHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-word",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
  },
};
