import MainLayout from "../../layouts/MainLayout";
import { Box, Button, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableComponent from "../../Components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import PopupModel from "../../Components/Modal";
import { useState } from "react";
import PersonsModel from "../../Components/Modal/PersonsModel";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { theme } from "../../constant/Theme";
const PersonsMattersPages = () => {
  const navigate = useNavigate();
  const [openPersons, setOpenPersons] = useState(false);
  const handleOpen = () => setOpenPersons(true);
  const handleClose = () => setOpenPersons(false);

  const HeadingTable = ["Matter", "Role", "Type", "Policy limit", "Policy number", "Action"];
  const ValuesKeysTable = ["cases", "role", "insuranceType", "policyLimit", "policyNumber"];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterSearch, setFilterSearch] = useState({});
  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const [limit, setLimit] = useState(10);
  const { get, setBearerToken } = useAxios();
  const { matter } = useMultiFileUpload();

  const location = useLocation();
  const { viewRecord } = location.state || {};
  console.log("viewRecord:", viewRecord);
  const fetchData = async () => {
    if(!viewRecord?._id) {
      return
    }
    let endpoint = `/personSignle/${viewRecord?._id}/?page=${currentPage}&limit=${limit}`;
    console.log("endPoint:", endpoint);

    if (searchText) {
      endpoint += `&search=${searchText}`;
    }

    // Add global filter if available
    if (matter) {
      endpoint += `&global=${encodeURIComponent(JSON.stringify(matter))}`;
    }

    // Add filterSearch if available
    if (filterSearch && Object.keys(filterSearch).length > 0) {
      endpoint += `&filter=${encodeURIComponent(JSON.stringify(filterSearch))}`;
    }
    setBearerToken(token);
    const response = await get(endpoint);
    return response?.data?.data;
  };
  const { data, isLoading } = useQuery(["personData",viewRecord, matter, currentPage, searchText, limit, filterSearch], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  let finalData = data;
  if (data) {
    const manipulatedData = data?.person?.paginatedCases?.map((caseDoc) => {
      // Create a deep copy of cases
      const casesCopy = caseDoc?.cases ? JSON.parse(JSON.stringify(caseDoc.cases)) : [];
      const caseFilter = casesCopy.map((v) => v.title);
      const caseFilterId = casesCopy.map((v) => v._id);
      return {
        _id: caseDoc?._id,
        caseIds: caseFilterId,
        firstName: caseDoc?.firstName,
        cases: caseFilter ,
        role: caseDoc?.role,
        policyLimit: caseDoc?.policyLimit,
        policyNumber: caseDoc?.policyNumber,
        insuranceType: caseDoc?.insuranceType,
        type: caseDoc?.type,
        physicianType: caseDoc?.physicianType,
        createdAt: caseDoc?.createdAt,
      };
    });

    finalData = {
      ...finalData,
      data: manipulatedData,
    };
    console.log("data value:", manipulatedData
    );
  }
 

  return (
    <MainLayout>
      <PopupModel
        open={openPersons}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "90%", sm: "auto" },
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#262535",
          boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
          border: "1px solid #686DFC",
        }}
        handleClose={handleClose}
        cards={<PersonsModel closeModal={handleClose} filter={filterSearch} setFilter={setFilterSearch} />}
      />
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme?.palette?.background?.paper,
            color: "white",
          }}
        >
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
            }}
          >
            <CssBaseline />
            {/* here is heading  */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography variant="h5" color={theme?.palette?.primary?.main}>
                  Persons /
                  <Box component="span" color={theme?.palette?.primary?.secondaryheading}>
                    {data?.firstName}
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                onClick={() => navigate("/persons/personsMatter/addpersonMatter", { state: { userData: viewRecord } })}
                  
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 0, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    boxShadow: theme?.shadows?.[0],
                    "&:hover, &:focus": {
                      boxShadow: theme?.shadows?.[1],
                      backgroundColor: "white",
                      color: "#6a1b9a",
                    },
                    fontSize: "12px",
                    backgroundColor: theme?.palette?.secondary?.backgroundColor,
                    fontWeight: "600",
                    fontFamily: "poppins",
                    textTransform: "uppercase",
                  }}
                >
                  <AddIcon fontSize="small" sx={{ marginRight: "4px" }} />
                  ASSIGN NEW MATTER
                </Button>
              </Grid>
              {/* table section */}
              <Grid item xs={12} sm={12}>
                <TableComponent
                  filterSearch={filterSearch}
                  setFilterSearch={setFilterSearch}
                  filter={true}
                  handleOpen={handleOpen}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  limit={limit}
                  setLimit={setLimit}
                  data={finalData}
                  headingTable={HeadingTable}
                  valuesKeys={ValuesKeysTable}
                  status={false}
                  statusActiveUrl={""}
                  // viewPageUrl={"/persons/viewPersons"}
                  // deleteUrl={"/deleteCase"}
                  isDelete={false}
                  // editPageUrl={"/persons/updatePerson"}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </MainLayout>
  );
};

export default PersonsMattersPages;
