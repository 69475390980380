import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SvgIcon, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { state } from "../../constant/ConstantStateData";
import { inputPersonStyles } from "../../pages/Persons/style";
import { theme } from "../../constant/Theme";
import LabeledTextField, { CustomSelectDropDown, NoteInput } from "../../constant/ConstantInputField";
import TabsConstants from "../../constant/TabsConstants";





// Function to dynamically choose schema based on 'type'
export const validationSchemaStepOne = Yup.object({
//   type: Yup.string().required("Type is required"),
// }).shape({
//   ...baseSchema.fields,
//   firstName: Yup.string().when("type", {
//     is: "person",
//     then: () => Yup.string().min(3, "First name must be at least 3 characters.").required("Required"),
//     otherwise: () => Yup.string().notRequired(),
//   }),
//   lastName: Yup.string().when("type", {
//     is: "person",
//     then: () => Yup.string().min(3, "Last name must be at least 3 characters.").required("Required"),
//     otherwise: () => Yup.string().notRequired(),
//   }),
  // gender: Yup.string().when("type", {
  //   is: "person",
  //   then: () => Yup.string().required("Required"),
  //   otherwise: () => Yup.string().notRequired(),
  // }),
});

export const validationSchemaStepTwo = Yup.object({
  // caseIds: Yup.array().of(Yup.string()).min(1, "Select at least one matter").required("Required"),
  // caseId: Yup.string().required("Required"),
  // role: Yup.string().required("Required"),
  // policyLimit: Yup.string(),
  // policyNumber: Yup.string(),
  // note: Yup.string(),
});
let stateNames = state;

export const PersonMatterRenderStep = (activeStep, formik) => {
  const [openCase, setOpenCase] = useState(false);
  const handleOpen = () => setOpenCase(true);
  const handleClose = () => setOpenCase(false);

  const [customCompanyName, setCustomCompanyName] = useState("");
  const { user } = useSelector((state) => state?.reducer);
  const [allClient, setAllClient] = useState([]);
  const [emailError, setEmailError] = useState();
  const [allCase, setAllCase] = useState([]);
  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "caseDropdown", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "getClients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        let result = response?.data?.data.map((v) => v.companyName).filter((name) => name && name.trim() !== ""); // Filter out empty names

        if (result.length === 0) {
          result = ["N/A"]; // Set to "N/A" if no valid data is returned
        }

        const currentCompanyName = formik.values.companyName;
        if (currentCompanyName && currentCompanyName.trim() !== "") {
          setAllClient([...result, currentCompanyName]);
        } else {
          setAllClient(result);
        }
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClient(["N/A"]); // Set to "N/A" if the API call fails
    }
  };

  useEffect(() => {
    getAllCase();
    getAllClients();
  }, []);

  const MenuProps = {
    // component: CustomMenu,

    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <TabsConstants formik={formik} />
        </>
      );
    case 1:
      return (
        <>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color: formik.touched.caseIds && Boolean(formik.errors?.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                  marginBottom: "8px",
                }}
              >
                Matters *
              </Typography>
              <FormControl
                required
                variant="outlined"
                name="caseIds"
                size="small"
                fullWidth
                error={formik.touched.caseIds && Boolean(formik.errors.caseIds)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.caseIds && Boolean(formik.errors.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.caseIds && Boolean(formik.errors.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.secondary?.main,
                  },
                }}
              >
                <Autocomplete
                  multiple
                  id="caseIds"
                  name="caseIds"
                  fullWidth
                  size="small"
                  options={allCase}
                  disableClearable={true}
                  MenuProps={MenuProps}
                  getOptionLabel={(option) => option.title}
                  onBlur={(e) => {
                    formik.setTouched({ ...formik.touched, caseIds: true }); // Mark caseIds as touched
                    formik.handleBlur(e); // Let Formik handle blur
                  }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("caseIds", newValue ? newValue.map((value) => value._id) : []);
                    formik.setTouched({ ...formik.touched, caseIds: true }); // Mark caseIds as touched
                  }}
                  error={formik.touched.caseIds && Boolean(formik.errors.caseIds)}
                  helperText={formik.touched.caseIds && formik.errors.caseIds}
                  value={Array.isArray(formik.values.caseIds) ? formik.values.caseIds.map((id) => allCase.find((option) => option._id === id))?.filter((option) => option !== undefined) : []}
                  renderTags={(value, getTagProps) => (
                    <Box component="div">
                      {value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option.title}
                          deleteIcon={
                            <SvgIcon
                              sx={{
                                color: "#686DFC",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              <ClearIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#686DFC",
                                }}
                              />
                            </SvgIcon>
                          }
                          sx={{
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            height: "25px",
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 300, // Adjust as needed
                        overflowY: "auto",
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      maxHeight: 300,
                      overflowY: "auto",
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: "#686DFC",
                        },
                      },
                      "& .MuiAutocomplete-noOptions": {
                        color: "#FF7799", // Customize the color of the noOptionsText
                      },
                    },
                  }}
                  noOptionsText="No matter found"
                  renderInput={(params) => (
                    <TextField
                      name="caseIds"
                      {...params}
                      placeholder={formik.values.caseIds.length > 0 ? "" : "Select Matter"}
                      error={formik.touched.caseIds && Boolean(formik.errors.caseIds)}
                      helperText={formik.touched.caseIds && formik.errors.caseIds}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          width: "100%",
                          color: theme?.palette?.primary?.main, // Set text color
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: formik.touched.caseIds && Boolean(formik.errors.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: formik.touched.caseIds && Boolean(formik.errors.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.secondary?.main,
                        },
                        color: theme?.palette?.secondary?.main,
                        "&.Mui-focused": {
                          color: theme?.palette?.secondary?.main, // Change label color when focused
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: formik.touched?.caseIds && Boolean(formik.errors?.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                        },
                      }}
                    />
                  )}
                />
                {allCase.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No matters are added.</FormHelperText>}
              </FormControl>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color: formik.touched.caseIds && Boolean(formik.errors?.caseIds) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Matters *
              </Typography>
              <FormControl required variant="outlined" size="small" fullWidth error={formik.touched.case && Boolean(formik.errors.case)} sx={inputPersonStyles?.matterName(formik)}>
                <Autocomplete
                  id="case"
                  options={allCase}
                  disableClearable={true}
                  getOptionLabel={(option) => option.title}
                  autoComplete
                  size="small"
                  includeInputInList
                  value={allCase.find((option) => option._id === formik.values.caseId) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("case", newValue?._id || "");
                    formik.setFieldValue("caseId", newValue?._id || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      required
                      fullWidth
                      error={formik.touched.case && Boolean(formik.errors.case)}
                      helperText={formik.touched.case && formik.errors.case ? formik.errors.case : ""}
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.secondary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={inputPersonStyles?.TextField}
                    />
                  )}
                  PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: theme?.palette?.secondary?.main }} />}
                  ListboxProps={{
                    sx: inputPersonStyles.listbox,
                  }}
                  noOptionsText="No matters found"
                />

                {allCase.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No matter is added.</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{ color: theme?.palette?.secondary?.main, mb: "4px" }} // Optional styles for the label
              >
                Role *
              </Typography>
              <CustomSelectDropDown
                label="Role"
                name="role"
                value={formik.values.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role ? formik.errors.role : ""}
                options={["Healthcare Provider", "Insurance Adjuster"]}
                formik={formik}
                theme={theme}
                inputStyles={inputPersonStyles?.role(formik)}
                placeholder={"Select Role"}
              />
            </Grid>
            {formik.values.role === "Insurance Adjuster" && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme?.palette?.secondary?.main, mb: 1 }} // Optional styles for the label
                  >
                    Insurance Type
                  </Typography>
                  <CustomSelectDropDown
                    label="Insurance Type"
                    name="insuranceType"
                    value={formik.values.insuranceType}
                    error={formik.touched.role && Boolean(formik.errors.insuranceType)}
                    helperText={formik.touched.role ? formik.errors.insuranceType : ""}
                    options={["Liability Adjuster", "Property Damage Adjuster", "UM Adjuster"]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Insurance Type"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyNumber" label="Policy number" placeholder="Enter Policy number" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyLimit" label="Policy limit" placeholder="Enter Policy Limit" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
              </>
            )}

            {formik.values.role === "Healthcare Provider" && (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{ color: theme?.palette?.secondary?.main, mb: 1 }} // Optional styles for the label
                >
                  Physician Type
                </Typography>
                <CustomSelectDropDown
                  label="physician Type"
                  name="physicianType"
                  value={formik.values.physicianType}
                  error={formik.touched.role && Boolean(formik.errors.physicianType)}
                  helperText={formik.touched.role ? formik.errors.physicianType : ""}
                  options={["Treating Physician", "Non-treating Physician"]}
                  formik={formik}
                  theme={theme}
                  inputStyles={inputPersonStyles?.insuranceType(formik)}
                  placeholder={"Select Physician Type"}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <NoteInput
                formik={formik} // Pass formik to manage form state and validation
                label="Note " // Custom label
                placeholder="Add your notes here" // Custom placeholder
                name="note" // Field name for formik to manage the state
                rows={6} // Number of rows
                theme={theme} // Custom theme for color and styles
                inputStyles={inputPersonStyles?.note} // Custom styles for the input
              />
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
