import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import {  PersonRenderStep, validationSchemaStepOne, validationSchemaStepTwo } from "../../Validation/PersonValidation";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { theme } from "../../../constant/Theme";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
const PersonForm = ({ activeStep, steps, handleBack, handleNext }) => {
  const validationSchemas = [validationSchemaStepOne, validationSchemaStepTwo];
  const currentValidationSchema = validationSchemas[activeStep];
  const { user } = useSelector((state) => state?.reducer);
  const navigate = useNavigate();
  const { matter } = useMultiFileUpload();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const initialValues = {
    type: "person",
    caseId: matter?._id,
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    personcompanyName:'',
    role: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    policyLimit: "",
    policyNumber: "",
    note: "",
    insuranceType: "",
    gender: "",
    physicianType: "",
  };
  
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const handleFormSubmit = (values, actions) => {



    console.log(values,'i am person')
    // Check if the fields of the current step are valid
    currentValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        if (activeStep < validationSchemas.length - 1) {
          actions.setTouched({});

          actions.setSubmitting(false);
          handleNext();
        } else {
   
          // Final submit action

          actions.setSubmitting(false);

          try {
            const token = user?.userData?.token;
            setIsSubmitting(true);
            setEmailError(null);
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
              formData.append(key, values[key]);
            });

            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "person", values, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (response?.status == 200) {
              showSuccessToastMessage("Person has been added successfully.");
              navigate("/persons");

              setIsSubmitting(true);
            }
            // Handle successful response
          } catch (error) {
            // showErrorToastMessage("Error! please try again");
            showErrorToastMessage(error?.response?.data?.emailError + ".");
            // setIsSubmitting(false);
          }
        }
      })
      .catch((errors) => {
        actions.setErrors(errors);

        // isSubmitting(false);
      });
  };
  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleFormSubmit} enableReinitialize>
      {(formik) => (
        <Form>
          <Paper
            sx={{
              width: "100%",
              backgroundColor: theme?.palette?.background?.paper,
              color: "lightgray",
              margin: "25px 0 ",
              boxShadow: "none",
            }}
            style={{ boxShadow: 0 }}
          >
            {PersonRenderStep(activeStep, formik, emailError, setEmailError)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                pt: 2,
              }}
            >
              {activeStep === 0 && (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/persons")}
                  sx={{
                    width: { xs: "100%", sm: "120px" },
                    bottom: "0px",
                    mt: { xs: 1, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    color: theme?.palette?.secondary?.backgroundColor,
                    fontSize: "12px",
                    borderColor: "white",
                    border: "2px solid",
                    backgroundColor: "transparent",
                    "&:hover": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                      backgroundColor: theme?.palette?.secondary?.backgroundColor,
                      color: "white",
                    },
                    "&.Mui-disabled": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    fontFamily: "sans-serif",
                  }}
                >
                  Cancel
                </Button>
              )}

              {activeStep > 0 && activeStep != 2 && (
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  disabled={activeStep === 0}
                  sx={{
                    width: { xs: "100%", sm: "120px" },
                    bottom: "0px",
                    mt: { xs: 1, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    color: theme?.palette?.secondary?.backgroundColor,
                    fontSize: "12px",
                    borderColor: "white",
                    border: "2px solid",
                    backgroundColor: "transparent",
                    "&:hover": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                      backgroundColor: theme?.palette?.secondary?.backgroundColor,
                      color: "white",
                    },
                    "&.Mui-disabled": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    fontFamily: "sans-serif",
                  }}
                >
                  Previous
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  width: { xs: "100%", sm: "120px" },
                  mt: { xs: 0, sm: 1.5 },
                  mb: { xs: 0, sm: 2 },
                  boxShadow: theme?.shadows?.[0],
                  "&:hover, &:focus": {
                    boxShadow: theme?.shadows?.[1],
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },
                  "&:disabled": {
                    backgroundColor: "#4A4C64",
                    color: "white",
                    boxShadow: "0px 4px 8px #4A4C64",
                  },
                  fontSize: "12px",
                  backgroundColor: theme?.palette?.secondary?.backgroundColor,
                  fontWeight: "600",
                  fontFamily: "poppins",
                }}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default PersonForm;
