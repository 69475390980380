import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "../../../services/http.service";
import { fetchTokenAndPostChatbot } from "../../../services/http.chatbot.service";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { useQuery, useQueryClient } from "react-query";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import notificationService from "../../../services/notification.service";
import ChatWaitingIcon from "../../Icons/ChatWaitingIcon";
import ChatLoaderIcon from "../../Icons/ChatLoaderIcon";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import QuerieIcon from "./QuerieIcon";
import useChatMessages from "../../../hooks/useChatMessages";
import MessageContent from "./MessageContent ";
import { theme } from "../../../constant/Theme";

const QueriesForm = ({ chatValue, chatHistory, setChatHistory }) => {
  const { user } = useSelector((state) => state.reducer);

  const { isLoading, allChat: data, newMessageId } = useChatMessages();
  const [loader, setLoader] = useState(false);
  const [chatData, setChatData] = useState([]);

  const [threadId, setThreadId] = useState("");
  const { matter } = useMultiFileUpload();
  const queryClient = useQueryClient();
  const { post, setBearerToken, get } = useAxios();
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    // validationSchema: {}, // You can define a Yup validation schema here
    onSubmit: async (values, { resetForm }) => {
      try {
        // Validate the form
        if (!values.title) {
          return;
        }
        if (!chatValue.caseId || !chatValue.category) {
          return;
        }

        setLoader(true);

        let fileData;

        if (chatValue.category === "medical" && chatValue?.type) {
          // Check if personsId exists for more specific logic
          if (chatValue?.personsId) {
            // Map the personData to fileUrl if the personsId matches
            fileData = chatValue.personData
              .map((v) => {
                if (v.personsId === chatValue.personsId) {
                  return v.fileUrl; // Return the fileUrl if personsId matches
                }
                return null; // Return null for non-matching personsId
              })
              .filter((url) => url); // Filter out null or empty values
          } else {
            // If personsId is not provided, use the general fileUrl mapping
            fileData = chatValue.personData.map((v) => v.fileUrl);
          }
        } else {
          // Fallback to chatHistory or chatValue.items
          fileData = chatHistory?.files || chatValue?.items;
        }

        // Fallback if no fileData is found
        if (!fileData || fileData.length === 0) {
          fileData = []; // Fallback to an empty array if no files are found
        }

        let witnessData;
        if (chatHistory?.person_id || chatValue?.personsId) {
          witnessData = chatValue.personData.find((v) => v.personsId === chatValue?.personsId || chatHistory?.person_id)?.witnessData;
        }
        // Log to check the final data

        const apiData = {
          question: values.title,
          file: fileData,
          thread_id: chatHistory ? chatHistory.thread_id : "",
          matter_id: chatValue.caseId,
          witness: chatHistory?.person_id || chatValue?.personsId,
          witnessData: chatValue?.witnessData || witnessData,
          roles: chatValue.category == "person" ? [chatValue?.type] : chatValue?.type,
          max_tokens: chatValue?.maxTokens || "1000",
          sources: chatHistory?.source_number || chatValue?.sources || 4,
          assistant_id: "asst_SZJsPGkHhIqeqzBbkAYIXlQ2",
          organization: user?.organizationData?._id,
          api_key: process.env.CHATGTP_OPEN_API_KEY || "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        // Fetch external API
        const externalApiResponse = await fetchTokenAndPostChatbot(`/${chatValue.category}/retrieve`, apiData);

        // Check if the external API call was successful
        if (externalApiResponse.status === 200 && externalApiResponse.data) {
          const token = user?.userData?.token;
          setBearerToken(token);
          const data = {
            chatId: chatValue.items?.id,
            question: values.title,
            answer: externalApiResponse.data.answer,
            moduleName: chatValue?.category || chatHistory?.moduleName || "",
            title: externalApiResponse.data.file,
            type: chatValue?.type || chatHistory?.type || "",
            files: chatHistory?.files || chatValue?.items,
            assistant_id: "asst_SZJsPGkHhIqeqzBbkAYIXlQ2",
            person_id: chatHistory?.person_id || chatValue?.personsId || "",
            source_number: chatHistory?.source_number || chatValue?.sources || 4,
            matter_id: chatValue.caseId,
            max_tokens: chatValue?.maxTokens,
            thread_id: externalApiResponse.data?.thread_id || chatHistory?.thread_id || "",
            page: externalApiResponse.data?.page,
            sources: externalApiResponse.data?.sources,
          };

          // Post data to local API
          const response = await post(`chatbot`, data);

          if (response) {
            setLoader(false);
            setChatHistory(data);
            resetForm();
            queryClient.invalidateQueries("thread");
            // notificationService.success("Successfully generated response");
          } else {
            throw new Error("Error: Failed to submit the data to the server.");
          }
        } else {
          throw new Error("Error: External API call failed.");
        }
      } catch (error) {
        console.error("Submission error:", error);
        notificationService.error(error.message || "An error occurred during submission.");
        setLoader(false); // Ensure loader is disabled if an error occurs
      } finally {
        resetForm(); // Reset the form after submission or error
      }
    },
  });

  const [expanded, setExpanded] = React.useState([]); // Track multiple expanded panels

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => (isExpanded ? [...prevExpanded, panel] : prevExpanded.filter((item) => item !== panel)));
  };

  const chatContainerRef = useRef(null);

  // Scroll to the bottom of the chat when data changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scroll({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // Ensure smooth scrolling
      });
    }
  }, [data, loader]);
  function formatParagraphForMarkdown(paragraph) {
    let formattedParagraph = paragraph.replace(/(\d+)\.\s/g, "\n$1. ");
    formattedParagraph = formattedParagraph.replace(/(\d+)\.\s/g, "\n\n$1. ");
    formattedParagraph = formattedParagraph.replace(/(\d+\..+)(?!\n\n)/g, "$1\n\n");
    return formattedParagraph.trim();
  }

  return (
    <Box sx={{ display: "flex", height: "100%", justifyContent: "space-between", flexDirection: "column" }}>
      <Box sx={{ overflowY: "auto" }} ref={chatContainerRef}>
        {data?.map((v, j) => (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center ", justifyContent: "end", gap: "10px", marginTop: "10px", fontSize: "12px" }}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: theme?.typography?.fontFamily,
                  background: theme?.palette?.background?.paper,
                  p: 1,
                  borderRadius: "4px",
                  maxWidth: "80%",
                }}
              >
                {v.question}
              </Typography>

              <Typography
                sx={{
                  p: "7px",
                  backgroundColor: theme?.palette?.primary?.secondaryheading,
                  width: "38px",
                  height: "38px",
                  borderRadius: "50%",
                  color: "#2B2C40",
                  marginRight: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {`${user?.userData?.firstName?.charAt(0)}${user?.userData?.lastName?.charAt(0)}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
              <Box
                component="img"
                src="/images/Frame.png"
                alt="logo"
                sx={{
                  width: "50px",
                  height: "auto",
                  p: 1,
                }}
              ></Box>
              <Typography variant="body1" sx={{ color: theme?.palette?.primary?.secondaryheading, fontFamily: "Public Sans", maxWidth: "90%", fontSize: "14px" }}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{formatParagraphForMarkdown(v?.answer)}</ReactMarkdown>
              </Typography>
              {/* <MessageContent message={v} isNewMessage={v.id === newMessageId} /> */}
            </Box>
            {v?.sources.map((val, i) => (
              <Box>
                <Accordion
                  // expanded={expanded === val.source + i + j}
                  // onChange={handleExpansion(val.source + i + j)}
                  expanded={expanded.includes(val.source + i + j)} // Check if the panel is in the expanded array
                  onChange={handleExpansion(val.source + i + j)}
                  sx={[
                    {
                      boxShadow: "none", // Remove the box shadow
                      borderBottom: "1px solid #4A4C64",

                      "&:before": {
                        display: "none",
                      },
                    },
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={`panel${val.source + i + j}-header`}
                    sx={{
                      background: expanded.includes(val.source + i + j) ? "#2B2C40" : "#242333",
                      color: theme?.palette?.primary?.secondaryheading,

                      "& .MuiSvgIcon-root": {
                        color: expanded.includes(val.source + i + j) ? "#686DFC" : theme?.palette?.primary?.secondaryheading,
                      },
                    }}
                  >
                    <Typography sx={{ color: expanded === val.source + i + j ? "#686DFC" : theme?.palette?.primary?.secondaryheading }}>{val.source}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: expanded.includes(val.source + i + j) ? "#2B2C40" : "#242333", color: theme?.palette?.primary?.secondaryheading }}>
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      <Typography variant="body1" sx={{ color: "#A6A7C1", fontFamily: "Public Sans", fontStyle: "italic", fontSize: "14px" }}>
                        {" "}
                        Source:
                      </Typography>
                      <Typography variant="body1" sx={{ color: theme?.palette?.primary?.secondaryheading, fontFamily: "Public Sans", fontStyle: "italic", fontSize: "14px" }}>
                        {val.file}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      <Typography variant="body1" sx={{ color: "#A6A7C1", fontFamily: "Public Sans", fontStyle: "italic", fontSize: "14px" }}>
                        {" "}
                        Page:
                      </Typography>
                      <Typography variant="body1" sx={{ color: theme?.palette?.primary?.secondaryheading, fontFamily: "Public Sans", fontStyle: "italic", fontSize: "14px" }}>
                        {val.page}
                      </Typography>
                    </Box>

                    <Typography sx={{ fontStyle: "italic", pb: "20px" }}>
                      <Typography dangerouslySetInnerHTML={{ __html: val.content.replace(/\n/g, "<br />") }} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        ))}

        {loader || (isLoading && !data) ? (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: "10px", marginTop: "10px" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "#A6A7C1",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Public Sans",
                  background: theme?.palette?.background?.paper,
                  p: 1,
                  borderRadius: "4px",
                  maxWidth: "80%",
                }}
              >
                {formik?.values?.title}
              </Typography>
              <Typography
                sx={{
                  p: "7px",
                  backgroundColor: theme?.palette?.primary?.secondaryheading,
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  color: theme?.palette?.background?.paper,
                  marginRight: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {`${user?.userData?.firstName?.charAt(0)}${user?.userData?.lastName?.charAt(0)}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
              <Box
                component="img"
                src="/images/Frame.png"
                alt="logo"
                sx={{
                  width: "55px",
                  height: "auto",
                  p: 1,
                }}
              ></Box>
              <ChatWaitingIcon />
            </Box>
          </Box>
        ) : (
          <>
            {/* Fallback when no data */}
            {data?.length == 0 && (
              <Box sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: "column", height: "100%" }}>
                <QuerieIcon />
              </Box>
            )}
          </>
        )}
      </Box>
      <Box sx={{ color: "lightgray", background: "#242333", borderRadius: "1px", padding: "0 10px 10px 10px", marginTop: "20px" }} component="form" noValidate onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end", // Align items at the bottom
              width: "100%",
              bgcolor: theme?.palette?.background?.paper,
              borderRadius: "5px",
              padding: "7px",
            }}
          >
            <TextField
              error={formik.touched.title && Boolean(formik.errors?.title)}
              autoComplete="given-title"
              name="title"
              id="title"
              placeholder="Type your message here..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={loader ? "" : formik.values.title}
              disabled={loader}
              helperText={formik.touched?.title && formik.errors?.title}
              multiline
              minRows={1}
              maxRows={4}
              sx={{
                flexGrow: 1, // Ensures TextField takes up remaining space
                color: "#A6A7C1",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1",

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2B2C40",
                    color: "red",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2B2C40",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent", // Yellow border color on focus
                  },
                },
                "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2B2C40", // Ensure disabled state has the correct border color
                },
                "& textarea": {
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for Chrome, Safari, and Edge
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: loader ? "red" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: loader ? "#C8C9DB" : "#C8C9DB",
                  // loader ? "#2B2C40" : "#2B2C40"
                },
                onKeyDown: (e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line from being created
                    formik.handleSubmit(); // Submit the form or send the message
                  }
                },
              }}
            />
            <Button
              variant="contained"
              // size="medium"
              type="submit"
              sx={{
                marginRight: "9px",
                textTransform: "capitalize",
                backgroundColor: loader ? "#686DFC" : "#8A8BA1",
                color: "#ffff",
                borderRadius: "50%",
                p: "0",
                height: "40px", // Set equal height
                // width: "px",
                minWidth: "40px", // Prevent shrinking
                minHeight: "40px",
                "&:hover": {
                  backgroundColor: loader ? "#686DFC" : "#8A8BA1", // Keep blue background when hovered and loading
                },
                "&.Mui-disabled": {
                  backgroundColor: "#686DFC", // Custom disabled color
                  color: "#ffffff", // Text color when disabled
                },
              }}
            >
              {loader ? (
                <>
                  <ChatLoaderIcon />
                </>
              ) : (
                <SendIcon />
              )}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default QueriesForm;
