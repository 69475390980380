import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#A6A7C1", // heading color
      secondaryheading: "#F2F3FB", // secondary heading color
      subscriptionHeading: "#FFFFFF",
      heading: "#686DFC",
      iconColor: "linear-gradient(to  bottom, #AB82F9, #696CFF)",
      tertiaryHeading: "#E6E7F2",
    },
    secondary: {
      main: "#C8C9DB", //input field color and aslo heading
      dangerMain: "#D32F2F",
      inputColor: "#4A4C64",
      hoverColor: "#C8C9DB",
      backgroundColor: "#686DFC",
      cancelBtn: "#4A4C64",
    },
    background: {
      paper: "#2B2C40",
    },
    //   here defind the divider
    divider: "#4A4C64",
  },

  typography: {
    fontFamily: "Public Sans ,sans-serif",
  },
  shadows: {
    0: "0px 4px 8px rgba(104, 109, 252, 0.5)",
    1: "0px 6px 12px rgba(104, 109, 252, 0.7)",
  },
});
