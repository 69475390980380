import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import QueriesForm from "../../Components/Forms/QueriesForm";
import HistoryForm from "../../Components/Forms/QueriesForm/HistoryForm";
import QuerieEditIcon from "../../Components/Icons/QuerieEditIcon";
import QueriesidebarIcon from "../../Components/Icons/QueriesidebarIcon";
import QuerieDropdwon from "../../Components/Forms/QueriesForm/QuerieDropdown";
import useChatMessages from "../../hooks/useChatMessages";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { theme } from "../../constant/Theme";
const QueriesPage = () => {
  const { chatHistory, setChatHistory, setAllChat, matter } = useMultiFileUpload();
  // const [chatHistory, setChatHistory] = useState(null);

  const formik = useFormik({
    initialValues: {
      category: chatHistory?.moduleName || "",
      items: chatHistory?.files || [],
      caseId: chatHistory?.matter_id || [matter?._id] || [],
      chatId: "",
      maxTokens: chatHistory?.max_tokens || "",
      sources: chatHistory?.source_number || "",
      personsId: chatHistory?.person_id || "",
      type: chatHistory?.type || "",
      fillingParty: chatHistory?.person_id || "",
      role: "",
      roleId: "",
      showFilter: true,
    },
    onSubmit: async (values, { resetForm }) => {},
    enableReinitialize: true,
  });
  useEffect(() => {
    if (chatHistory) {
      // Perform any action you need when chatHistory changes

      formik.setValues({
        category: chatHistory?.moduleName || "",
        items: chatHistory?.files || [],
        caseId: chatHistory?.matter_id || "",
        chatId: "",
        maxTokens: chatHistory?.max_tokens || "",
        sources: chatHistory?.source_number || "",
        personsId: chatHistory?.person_id || "",
        type: chatHistory?.type || "",
        fillingParty: chatHistory?.person_id || "",
        role: "",
        roleId: "",
        showFilter: true,
      });
    }
  }, [chatHistory]);
  // State to manage the visibility of HistoryForm
  const [isHistoryFormVisible, setIsHistoryFormVisible] = useState(true);
  return (
    <MainLayout>
      {/* First section with 20% height */}
      <Grid container spacing={2}>
        <QuerieDropdwon formik={formik} chatHistory={chatHistory} />
      </Grid>
      {/* height: "15vh" */}
      {/* Second section with 10% height */}
      <Grid container pt={2}>
        {/* height: "10vh"  */}
        <Grid
          item
          xs={3}
          sx={{
            borderRight: isHistoryFormVisible ? "1px solid #4A4C64" : "",
            backgroundColor: isHistoryFormVisible ? theme?.palette?.background?.paper : "#242333",
            color: "white",
          }}
        >
          <Paper
            sx={{
              p: "15px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme?.palette?.background?.paper,
              color: "white",
              justifyContent: "center",
              borderBottom: "1px solid #4A4C64",
              borderRadius: 0,
              height: "100%",
              boxShadow: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: isHistoryFormVisible ? "space-between" : "start",
                gap: isHistoryFormVisible ? "0" : "10px",
              }}
            >
              <Tooltip title={isHistoryFormVisible ? "Close sidebar" : "Open sidebar"} placement="bottom" arrow>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#686DFC",
                  }}
                  onClick={() => setIsHistoryFormVisible((prevState) => !prevState)}
                >
                  <QueriesidebarIcon />
                </Box>
              </Tooltip>
              <Tooltip title="New chat" placement="bottom" arrow>
                <Box
                  onClick={() => {
                    setChatHistory({});
                    setAllChat([]);
                  }}
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <QuerieEditIcon />
                </Box>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={9}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: theme?.palette?.secondary?.main,
              background: theme?.palette?.background?.paper,
              borderBottom: "1px solid #4A4C64",
              height: "100%", // Ensures full height in this section
            }}
          >
            <Box
              component="img"
              src="/images/Frame.png"
              alt="logo"
              sx={{
                width: "55px",
                height: "auto",
                p: 1,
              }}
            />
            <Typography variant="h6" sx={{ fontFamily: "Public Sans", fontSize: "18px" }}>
              Magic AI Chatbot
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Third section with remaining height (70% of viewport) */}
      <Grid
        container
        sx={(theme) => ({
          position: "relative",
          height: "69vh", // Set to 75% of the viewport height by default
          // [theme.breakpoints.down("sm")]: {
          //   height: "60vh", // Smaller height for small screens (e.g., mobile, below 600px)
          // },
          // [theme.breakpoints.up("md")]: {
          //   height: "69vh", // Larger height for medium screens (e.g., above 960px)
          // },
          // [theme.breakpoints.up("lg")]: {
          //   height: "69vh", // Set larger height for large screens (e.g., above 1200px)
          // },
          flex: "1",
        })}
      >
        <Grid
          item
          sx={{
            position: "absolute",
            left: isHistoryFormVisible ? 0 : "-25%", // Adjust left based on visibility
            width: isHistoryFormVisible ? "25%" : "0", // Animate width
            height: "100%", // Ensure history form takes full height
            backgroundColor: theme?.palette?.background?.paper,
            color: "white",
            flex: 3,
            overflow: "auto", // Allow content overflow with scrolling
            transition: "left 0.5s ease, width 0.5s ease", // Animate left and width
            borderRight: isHistoryFormVisible ? "1px solid #4A4C64" : "none", // Only show border when visible
          }}
        >
          <HistoryForm chatHistory={chatHistory} setChatHistory={setChatHistory} formik={formik} />
        </Grid>

        <Grid
          item
          sx={{
            width: isHistoryFormVisible ? "75%" : "100%", // Adjust width based on visibility of the history form
            marginLeft: isHistoryFormVisible ? "25%" : "0", // Ensure main content shifts correctly
            height: "100%", // Ensure the main content also takes full height
            transition: "width 0.5s ease, margin-left 0.5s ease", // Animate width and margin
            flex: 3,
          }}
        >
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#242333",
              height: "100%",

              overflow: "auto",
            }}
          >
            <QueriesForm
              chatValue={formik.values}
              chatHistory={chatHistory}
              // isLoading={isLoading}
              // data={allChat}
              setChatHistory={setChatHistory}
              isHistoryFormVisible={isHistoryFormVisible}
            />
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default QueriesPage;
