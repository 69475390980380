import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

export const inputDocumentPleadingStyles = {
  cases: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.main,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main,
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.secondary?.main,
    },
  }),
  deposition: (formik) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.type && Boolean(formik.errors.type) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.type && Boolean(formik.errors.type) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main, // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.secondary?.main,
    },
  }),
  title: (formik) => ({
    width: "100%",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: theme?.palette?.primary?.main, // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.title && Boolean(formik.errors.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.title && Boolean(formik.errors.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
      },
    },
  }),
  submit: {
    width: { xs: "100%", sm: "120px" },
    mt: { xs: 0, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    boxShadow: theme?.shadows?.[0],
    "&:hover, &:focus": {
      boxShadow: theme?.shadows?.[1],
      backgroundColor: "white",
      color: "#6a1b9a",
    },
    "&:disabled": {
      backgroundColor: theme?.palette?.secondary?.cancelBtn,
      color: "white",
      boxShadow: "0px 4px 8px #4A4C64",
    },
    fontSize: "12px",
    backgroundColor: theme?.palette?.secondary?.backgroundColor,
    fontWeight: "600",
    fontFamily: theme?.typography?.fontFamily,
  },
  cancel: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: theme?.palette?.secondary?.backgroundColor,
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    backgroundColor: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: theme?.palette?.secondary?.backgroundColor,
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: theme?.typography?.fontFamily,
  },
};
