import { useFormik } from "formik";
import { theme } from "../../constant/Theme";

// Define a function that returns the styles based on formik props
export const getInputCourtStyle = {
  courtName: (formik) => ({
    width: "100%",
    fontFamily: theme?.typography?.fontFamily,
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: formik.touched.name && Boolean(formik.errors.name) ? theme?.palette?.secondary?.dangerMain : "#C8C9DB",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.secondary?.main,
    },
  }),
  inputText: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      color: "#A6A7C1", // Set text color
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C8C9DB", // Border color on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme?.palette?.secondary?.inputColor, // Yellow border color on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.secondary?.main,
    },
  },
  viewPaper: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    color: theme?.palette?.secondary?.main,
    padding: "20px",
  },
  viewHeading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme?.palette?.primary?.main,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: "500",
  },
  viewSubHeading: {
    fontSize: "14px",
    lineHeight: "19.8px",
    color: theme?.palette?.primary?.secondaryheading,
    fontFamily: theme?.typography?.fontFamily,
  },
  backBtn: {
    padding: { xs: "7px 22px", xl: "20px 50px" },
    color: "#686DFC",
    fontSize: { xs: "10px", xl: "15px" },
    borderColor: "white",
    border: "2px solid",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
  },
  submit: {
    width: { xs: "100%", sm: "120px" },
    mt: { xs: 0, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
    "&:hover, &:focus": {
      boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
      backgroundColor: "white",
      color: "#6a1b9a",
    },
    "&:disabled": {
      backgroundColor: "#4A4C64",
      color: "white",
      boxShadow: "0px 4px 8px #4A4C64",
    },
    fontSize: "12px",
    backgroundColor: "#686DFC",
    fontWeight: "600",
    fontFamily: theme?.typography?.fontFamily,
  },
  cancel: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: "#686DFC",
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    backgroundColor: "transparent",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: "#686DFC",
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: theme?.typography?.fontFamily,
  },
};
