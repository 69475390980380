import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useFileActions from "../../hooks/useFileActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStaticFormattedDate from "../../hooks/useStaticDate";
import { theme } from "../../constant/Theme";
import { inputIssueStyles } from "./style";
const ViewDetailIssue = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const { viewRecord } = location.state || {};
  let CreatedDate = "";
  if (viewRecord?.createdAt) {
    const year = new Date(viewRecord?.createdAt).getFullYear();
    const month = new Date(viewRecord?.createdAt).toLocaleString("en-US", {
      month: "long",
    });
    const day = new Date(viewRecord?.createdAt).getDate();
    CreatedDate = `${day ?? ""} ${month ?? ""} ${year ?? ""}`;
  }
  const getFileName = (filePath) => {
    if (typeof filePath !== "string" || !filePath.includes("_")) {
      return filePath; // Return the original filePath if it's not a string or doesn't contain an underscore
    }
    const parts = filePath.split("_");
    return parts.length > 1 ? parts.slice(1).join("_") : filePath;
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { handleFileAction, FileViewModal } = useFileActions();
  const { date } = useStaticFormattedDate(viewRecord?.createdAt);
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: theme?.palette?.background?.paper,
                color: theme?.palette?.secondary?.main,
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component="div"
                    color={theme?.palette?.primary?.secondaryheading}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* , fontWeight: '600', lineHeight: '33.6px', fontSize: '28px' */}
                    <Typography
                      sx={{
                        // fontWeight: "600",
                        lineHeight: "33.6px",
                        fontSize: "28px !important",
                        fontFamily: theme?.typography?.fontFamily,
                      }}
                    >
                      {" "}
                      Issues Details
                    </Typography>
                    <Button
                      onClick={() => navigator("/issues")}
                      variant="outlined"
                      sx={{
                        padding: { xs: "7px 22px", xl: "20px 50px" },
                        color: "#686DFC",
                        fontSize: { xs: "10px", xl: "15px" },
                        borderColor: "white",
                        border: "2px solid",
                        "&:hover": {
                          border: "2px solid",
                          borderColor: "rgba(104, 109, 252, 0.7)",
                          color: "rgba(104, 109, 252, 0.7)",
                        },
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box>
                    <Typography
                      color={theme?.palette?.primary?.main}
                      sx={{
                        fontSize: "12px",
                        lineHeight: "24px",
                      }}
                    >
                      Date Created
                    </Typography>
                    <Typography
                      color={theme?.palette?.primary?.tertiaryHeading}
                      sx={{
                        fontSize: "12px",
                        lineHeight: "19.8px",
                      }}
                    >
                      {date}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {/* Case Title Date of certification Note Type Document */}
                <Grid item xs={12} sm={12}>
                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography sx={inputIssueStyles?.viewHeading}>Issue Name</Typography>
                    <Typography sx={inputIssueStyles?.viewSummaryHeading}>{viewRecord?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography sx={inputIssueStyles?.viewHeading}>Summary of Law</Typography>
                    <Typography sx={inputIssueStyles?.viewSummaryHeading}>{viewRecord?.summaryOfLaw}</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid>
                <Accordion
                  // expanded={expanded === val.source + i}
                  // onChange={handleExpansion(val.source + i)}

                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  sx={[
                    {
                      backgroundColor: theme?.palette?.secondary?.inputColor,
                      boxShadow: "none", // Remove the box shadow
                      borderBottom: "1px solid #4A4C64",
                      borderRadius: "4px",
                      border: "2px solid #4A4C64",
                      "&:before": {
                        display: "none",
                      },
                    },
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={`panelheader`}
                    sx={{
                      backgroundColor: theme?.palette?.secondary?.inputColor,

                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.secondaryheading,
                      },
                    }}
                  >
                    <Typography color={theme?.palette?.primary?.secondaryheading} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                      Case 1
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: expanded ? "#2B2C40" : "#242333", color: "#F2F3FB" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} my={3}>
                      <Typography
                        color={theme?.palette?.primary?.secondaryheading}
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "500",
                        }}
                      >
                        Document
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <Box>
                          <Typography
                            onClick={() => handleFileAction(`issues/${viewRecord._id}?file=1`, "view")}
                            target="_blank"
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                              cursor: "pointer",
                              color: "#686DFC",
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                          <FileViewModal />
                        </Box>
                        <Box>
                          <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(`issues/${viewRecord._id}?file=1`, "download")}>
                            <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Citation</Typography> <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.caseOneCitation}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Facts</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.factOne}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Law</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}>{viewRecord?.lawOne}</Typography>
                      </Grid>

                      {/* Ruling */}
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Ruling</Typography>

                        <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                          {viewRecord?.rullingOne}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  sx={[
                    {
                      margin: "10px 0",

                      backgroundColor: theme?.palette?.secondary?.inputColor,
                      boxShadow: "none", // Remove the box shadow
                      borderBottom: "1px solid #4A4C64",
                      borderRadius: "4px",

                      border: "2px solid #4A4C64",
                      "&:before": {
                        display: "none",
                      },
                    },
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={`panelheader`}
                    sx={{
                      backgroundColor: theme?.palette?.secondary?.inputColor,

                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.secondaryheading,
                      },
                    }}
                  >
                    <Typography color={theme?.palette?.primary?.secondaryheading} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                      Case 2
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: expanded ? "#2B2C40" : "#242333", color: "#F2F3FB" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} my={3}>
                      <Typography
                        color={theme?.palette?.primary?.secondaryheading}
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "500",
                        }}
                      >
                        Document
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <Box>
                          <Typography
                            onClick={() => handleFileAction(`issues/${viewRecord._id}?file=2`, "view")}
                            target="_blank"
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                              cursor: "pointer",
                              color: theme?.palette?.primary?.heading,
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                          <FileViewModal />
                        </Box>
                        <Box>
                          <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(`issues/${viewRecord._id}?file=2`, "download")}>
                            <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Citation</Typography>{" "}
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.caseTwoCitation ? viewRecord?.caseOneCitation : "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Facts</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.factTwo ? viewRecord?.factTwo : "N/A"}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Law</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.lawTwo ? viewRecord?.lawTwo : "N/A"}</Typography>
                      </Grid>

                      {/* Ruling */}
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Ruling</Typography>

                        <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                          {viewRecord?.rullingTwo ? viewRecord?.rullingTwo : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  sx={[
                    {
                      margin: "10px 0",

                      backgroundColor: theme?.palette?.secondary?.inputColor,
                      boxShadow: "none", // Remove the box shadow
                      borderBottom: "1px solid #4A4C64",
                      borderRadius: "4px",

                      border: "2px solid #4A4C64",
                      "&:before": {
                        display: "none",
                      },
                    },
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={`panelheader`}
                    sx={{
                      backgroundColor: theme?.palette?.secondary?.inputColor,

                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.secondaryheading,
                      },
                    }}
                  >
                    <Typography color={theme?.palette?.primary?.secondaryheading} sx={{ fontFamily: theme?.typography?.fontFamily }}>
                      Case 3
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: expanded ? "#2B2C40" : "#242333", color: "#F2F3FB" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} my={3}>
                      <Typography
                        color={theme?.palette?.primary?.secondaryheading}
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          fontWeight: "500",
                        }}
                      >
                        Document
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <Box>
                          <Typography
                            onClick={() => handleFileAction(`issues/${viewRecord._id}?file=3`, "view")}
                            target="_blank"
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                              cursor: "pointer",
                              color: theme?.palette?.primary?.heading,
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                          <FileViewModal />
                        </Box>
                        <Box>
                          <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(`issues/${viewRecord._id}?file=3`, "download")}>
                            <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Citation</Typography>{" "}
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.caseThreeCitation ? viewRecord?.caseThreeCitation : "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Facts</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.factThree ? viewRecord?.factThree : "N/A"}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Law</Typography>
                        <Typography sx={inputIssueStyles?.viewSubHeading}> {viewRecord?.lawThree ? viewRecord?.lawThree : "N/A"}</Typography>
                      </Grid>

                      {/* Ruling */}
                      <Grid item xs={12} sm={6}>
                        <Typography sx={inputIssueStyles?.viewHeading}>Ruling</Typography>

                        <Typography color={theme?.palette?.primary?.main} sx={inputIssueStyles?.viewSubHeading}>
                          {viewRecord?.rullingThree ? viewRecord?.rullingThree : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDetailIssue;
