import { theme } from "../../../constant/Theme";

const styles = {
  paper: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    color: "lightgray",
    margin: "25px 0 ",
    boxShadow: "none",
  },
  button: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: theme?.palette?.secondary?.backgroundColor,
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: theme?.palette?.secondary?.backgroundColor,
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: "sans-serif",
  },

  perviosuBtn: {
    width: { xs: "100%", sm: "120px" },
    bottom: "0px",
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    color: theme?.palette?.secondary?.backgroundColor,
    fontSize: "12px",
    borderColor: "white",
    border: "2px solid",
    "&:hover": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
      backgroundColor: theme?.palette?.secondary?.backgroundColor,
      color: "white",
    },
    "&.Mui-disabled": {
      border: "2px solid",
      borderColor: "rgba(104, 109, 252, 0.7)",
      color: "rgba(104, 109, 252, 0.7)",
    },
    fontFamily: "sans-serif",
  },
  submitButton: {
    width: { xs: "100%", sm: "120px" },
    mt: { xs: 1, sm: 1.5 },
    mb: { xs: 0, sm: 2 },
    boxShadow: theme?.shadows?.[0],
    "&:hover, &:focus": {
      boxShadow: theme?.shadows?.[1],
      backgroundColor: "white",
      color: "#6a1b9a",
    },
    "&:disabled": {
      backgroundColor: "#4A4C64",
      color: "white",
      boxShadow: "0px 4px 8px #4A4C64",
    },
    fontSize: "12px",
    backgroundColor: theme?.palette?.secondary?.backgroundColor,
    fontWeight: "600",
    fontFamily: "poppins",
  },
  box: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    pt: 2,
  },
};

export default styles;
