import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useAxios } from "../../../services/http.service";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";

const useQuerieDropdownHook = (formik) => {
  const { matter } = useMultiFileUpload();
  const { user } = useSelector((state) => state.reducer);
  const { setBearerToken, get } = useAxios();
  const token = user?.userData?.token;

  const querierCondtion = ["medical", "exhibit", "discovery", "pleading", "person"];

  // Set the bearer token when it's available
  if (token) {
    setBearerToken(token); // Set the token in Axios instance
  }

  // Function to fetch cases
  const fetchCases = async () => {
    const queryParams = [];
    if (matter) {
      queryParams.push(`global=${encodeURIComponent(JSON.stringify(matter))}`);
    }
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
  
    const response = await get(`caseDropdown${queryString}`);
  
    const cases = response?.data?.data || [];
  
    // If cases array is not empty, add "All" option; otherwise, return empty array
    return cases.length ? [{ _id: "all", title: "All" }, ...cases] : [];
  };
  

  // Function to fetch persons
  const fetchPersons = async () => {
    // Check if category exists before proceeding
    if (!formik.values.category) {
      return;
    }

    // Build the base endpoint URL
    let endpoint = `chatbotWitnessFilter/${encodeURIComponent(formik.values.category)}`;

    // If type exists, append it as a query parameter

    const queryParams = [];

    // if (formik.values?.caseId) {
    //   queryParams.push(`global=${encodeURIComponent(JSON.stringify(formik.values?.caseId))}`);
    // }

    if (formik?.values?.type) {
      endpoint += `?type=${encodeURIComponent(formik?.values?.type)}`;
    }

    // if (queryParams.length > 0) {
    //   endpoint += `?${queryParams.join("&")}`;
    // }

    const response = await get(endpoint, {
      headers: {
        "Content-Type": "application/json", // Use application/json for GET requests
      },
    });
    formik.setFieldValue("personData", response?.data);
    // Return the data from the response
    return response?.data || [];
  };

  const fetchTypes = async () => {
    if (!querierCondtion.includes(formik.values.category)) {
      return;
    }
    let endpoint = `chatbotTypeFilter/${formik.values.category}`;

    if (formik?.values?.caseId.length > 0) {
      endpoint += `?global=${encodeURIComponent(JSON.stringify(formik.values?.caseId))}`;
    }

    const response = await get(endpoint, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  };

  // Function to fetch file names
  const fetchFileNames = async () => {
    if (formik.values?.category !== "depositions") {
      throw new Error("Category is required to fetch file names.");
    }
    let endpoint = `getFileUrl/${formik.values.category}`;
    const queryParams = [];

    if (formik.values?.caseId) {
      queryParams.push(`global=${encodeURIComponent(JSON.stringify(formik.values?.caseId))}`);
    }

    if (formik.values.personsId) {
      queryParams.push(`person=${encodeURIComponent(formik.values.personsId)}`);
    }

    if (queryParams.length > 0) {
      endpoint += `?${queryParams.join("&")}`;
    }

    const response = await get(endpoint);
    
    return response?.data || [];
  };

  // Fetch cases using `useQuery`
  const { data: allCase = [], isLoading: isCasesLoading } = useQuery({
    queryKey: ["cases", matter],
    queryFn: fetchCases,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // fecth type filter
  const { data: allType = [], isLoading: isTypeLoading } = useQuery({
    queryKey: ["types", formik?.values?.category, formik?.values?.caseId],
    queryFn: fetchTypes,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Fetch persons using `useQuery`
  const { data: allPersons = [], isLoading: isPersonsLoading } = useQuery({
    queryKey: ["persons", formik?.values?.category, formik?.values?.type, formik?.values?.caseId],
    queryFn: fetchPersons, // Pass function reference
  });

  // Fetch file names using `useQuery`
  const { data: userFile = [], isLoading: isFilesLoading } = useQuery({
    queryKey: ["fileNames", formik?.values?.personsId],
    queryFn: fetchFileNames, // Pass function reference
  });

  return {
    allCase,
    allPersons,
    userFile,
    allType,
    querierCondtion,
    isTypeLoading,
    isCasesLoading,
    isPersonsLoading,
    isFilesLoading,
  };
};

export default useQuerieDropdownHook;
