import { Box, Typography } from "@mui/material";
import React from "react";
import QueriesunableIcon from "../../Icons/QueriesunableIcon";

const QuerieIcon = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "25px" ,marginTop: "10px"}}>
        <Box
          component="img"
          src="/images/MagicLogo.png"
          alt="logo"
          sx={{
            width: "60px",
            height: "auto",
            p: 1,
          }}
        ></Box>
        <Typography sx={{ color: "#FFFFFF", fontSize: "20px", textAlign: "center", fontFamily: "Public Sans" }}>
          Embracing Magic AI Chatbot for <br /> Document Generation
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          border: "1px solid #C8C9DB",
          borderRadius: "8px",
          maxWidth: "75%",
          margin: "auto",
          padding: "15px",
        }}
      >
        <QueriesunableIcon />
        <Typography sx={{ fontSize: "14px", fontFamily: "Public Sans", color: "#C8C9DB", textAlign: "center" }}>
          To begin, select the appropriate filters from the dropdowns above. Once you have made your selections, type your query below to chat with Magic AI.
        </Typography>
      </Box>
    </>
  );
};

export default QuerieIcon;
