import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSpring, animated } from 'react-spring'; // Import animation

const MessageContent = ({ message, isNewMessage }) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  // Animation for text rendering
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 300 } });

  useEffect(() => {
    if (isNewMessage && index < message.answer.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + message.answer[index]);
        setIndex(index + 1);
      }, 30); // Adjust speed of animation here

      return () => clearTimeout(timeout);
    }
  }, [index, isNewMessage, message.answer]);

  // Function to format markdown paragraph
  function formatParagraphForMarkdown(paragraph) {
    let formattedParagraph = paragraph.replace(/(\d+)\.\s/g, "\n$1. ");
    formattedParagraph = formattedParagraph.replace(/(\d+)\.\s/g, "\n\n$1. ");
    formattedParagraph = formattedParagraph.replace(/(\d+\..+)(?!\n\n)/g, "$1\n\n");
    return formattedParagraph.trim();
  }

  return (
    <Typography
      variant="body1"
      sx={{ color: "#F2F3FB", fontFamily: "Public Sans", maxWidth: "90%", fontSize: "14px" }}
    >
      {isNewMessage ? (
        <animated.div style={props}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {formatParagraphForMarkdown(displayText)}
          </ReactMarkdown>
        </animated.div>
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {formatParagraphForMarkdown(message.answer)} {/* Render complete markdown for old messages */}
        </ReactMarkdown>
      )}
    </Typography>
  );
};

export default MessageContent;
