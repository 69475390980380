import { Divider, Grid, Typography, TextField, Button, Autocomplete, Select, MenuItem, Box, FormControl, InputLabel, FormHelperText, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
const DocumentPleadingModel = ({ closeModal, filter, setFilter }) => {
  const user = useSelector((state) => state?.reducer?.user);
  // persons api
  const [allPerson, setAllPerson] = useState([]);
  const [isCleardisable, setisCleardisbale] = useState(filter && typeof filter == "object" && Object.keys(filter).length > 0);
  const [allCase, setAllCase] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allTitle, setAllTitle] = useState([]);
  // for hidden the filter
  const { matter } = useMultiFileUpload();

  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "pleadingGenerateFilter", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.cases);
        setAllTitle(response?.data?.titles);
        setAllStatus(response?.data?.statuses);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCase();
  }, []);
  const formik = useFormik({
    initialValues: {
      caseTitle: filter?.caseTitle || "",
      title: filter?.title || "",
      status: filter?.status || "",
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });

  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" color={theme?.palette?.primary?.tertiaryHeading} sx={{ fontFamily: theme?.typography.fontFamily }}>
            Filters
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
          <CloseIcon sx={{ color: "#E6E7F2", cursor: "pointer" }} />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ backgroundColor: theme?.palette?.divider, marginY: "10px" }} />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          {!matter && (
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.secondary?.main,
                  marginBottom: "4px",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Matter
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  id="caseTitle"
                  options={allCase}
                  fullWidth
                  size="small"
                  disableClearable
                  getOptionLabel={(option) => option || ""}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={allCase?.find((option) => option === formik.values.caseTitle) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("caseTitle", newValue || "");
                    formik.setFieldValue("caseId", newValue?._id || ""); // Update formik field for caseId
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.secondary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.secondary?.main,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          color: theme?.palette?.primary?.main,
                          "& fieldset": {
                            borderColor: theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: theme?.palette?.secondary?.main,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.secondary?.main,
                        },
                      }}
                    />
                  )}
                  PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: theme?.palette?.secondary?.main }} />}
                  ListboxProps={{
                    sx: {
                      maxHeight: 300,
                      overflowY: "auto",
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: theme?.palette?.primary?.heading,
                        },
                      },
                    },
                  }}
                  noOptionsText="No matters found"
                />
              </Box>
            </Grid>
          )}

          {/* witness */}
          <Grid item xs={12} sm={12}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.secondary?.main,
                marginBottom: "4px",
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Title
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.main,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.title && Boolean(formik?.errors?.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.title && Boolean(formik?.errors?.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.title && Boolean(formik?.errors?.title) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.secondary?.main,
                },
              }}
            >
              <Select
                labelId="title-label"
                id="witness"
                autoComplete="off"
                name="title"
                placeholder="Enter title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("title", e.target.value);
                  formik.setFieldValue("title", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: theme?.palette?.secondary?.main,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                {allTitle?.map((row) => (
                  <MenuItem key={row?._id} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.secondary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Status
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: theme?.palette?.primary?.main, // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.main, // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.secondary?.main,
                },
              }}
            >
              <Select
                labelId="status-label"
                id="status"
                autoComplete="off"
                name="status"
                label="status"
                placeholder="Enter Status"
                value={formik.values?.status}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: theme?.palette?.secondary?.main,
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
              >
                {allStatus?.map((row, index) => (
                  <MenuItem key={index} value={row} sx={{ textTransform: "capitalize" }}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* button */}
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",

              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <Button
              onClick={() => handleClearFilter()}
              variant="contained"
              disabled={!isCleardisable}
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: theme?.palette?.secondary?.inputColor,
                  // color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: theme?.palette?.secondary?.inputColor,
                fontWeight: "600",
                fontFamily: "Public Sans ,sans-serif",
              }}
            >
              clear filter
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "Public Sans ,sans-serif",
              }}
            >
              Apply filter{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentPleadingModel;
