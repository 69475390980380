import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Checkbox, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, SvgIcon, TextField, Typography } from "@mui/material";
import { allCategory } from "../../../constant/GeneratePleading";
import ClearIcon from "@mui/icons-material/Clear";
import { styles } from "../../../pages/Queries/Style";
import useQuerieDropdownHook from "./useQuerieDropdownHook";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";

const QuerieDropdwon = ({ formik, chatHistory }) => {
  const { allCase, allPersons, userFile, allType, querierCondtion, isCasesLoading, isPersonsLoading, isFilesLoading } = useQuerieDropdownHook(formik);

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("category", value);
    formik.setFieldValue("items", []);
    formik.setFieldValue("personsId", "");
    if (value === "client") {
      formik.setFieldValue("sources", 1);
    } else if (value === "medical") {
      formik.setFieldValue("sources", 0); // Disable or reset sources when medical is selected
    }
  };
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <Grid item xs={12}>
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>
        {/* Category Dropdown */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" size="small" fullWidth error={formik.touched.case && Boolean(formik.errors.case)} sx={styles?.input}>
              <Autocomplete
                id="case"
                multiple
                disableClearable={true}
                options={allCase}
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={
                  formik.values.caseId?.length === allCase.length
                    ? [{ _id: "all", title: "All" }] // Show "All" if all cases are selected
                    : allCase?.filter((option) => formik.values.caseId?.includes(option._id))
                }
                onChange={(event, newValue) => {
                  const isAllSelected = newValue.some((option) => option._id === "all");
                  const selectedIds = newValue.map((option) => option._id);
                  const allCaseIds = allCase.map((option) => option._id);

                  if (isAllSelected) {
                    if (formik.values.caseId?.length === allCase.length) {
                      formik.setFieldValue("caseId", []);
                    } else {
                      formik.setFieldValue("caseId", allCaseIds);
                    }
                  } else {
                    if (selectedIds.length === allCase.length) {
                      formik.setFieldValue("caseId", allCaseIds);
                    } else {
                      formik.setFieldValue("caseId", selectedIds);
                    }
                  }
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Matters"
                    variant="outlined"
                    fullWidth
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={formik.touched.case && formik.errors.case ? formik.errors.case : ""}
                    InputLabelProps={{
                      sx: {
                        color: "#C8C9DB",
                        "&.Mui-focused": {
                          color: "#A6A7C1",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#A6A7C1",
                        "& fieldset": {
                          borderColor: "#4A4C64",
                        },
                        "&:hover fieldset": {
                          borderColor: "#C8C9DB",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#A6A7C1",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const isAllSelected = formik.values.caseId?.length === allCase.length;
                  return (
                    <li {...props}>
                      <Checkbox
                        checked={selected || isAllSelected}
                        sx={{
                          color: isAllSelected ? "#A6A7C1" : "#A6A7C1",
                          backgroundColor: "transparent",
                          "&.Mui-checked": {
                            color: isAllSelected ? "#686DFC" : "#686DFC",
                            backgroundColor: "transparent",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.2rem",
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: "14px", color: isAllSelected ? "#A6A7C1" : "#A6A7C1" }} paddingLeft={1}>
                        {option.title}
                      </Typography>
                    </li>
                  );
                }}
                PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: "#C8C9DB" }} />}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "red",
                      textAlign: "center",
                    },
                  },
                }}
                renderTags={(value, getTagProps) => {
                  const maxVisibleChips = 1;
                  if (formik.values.caseId?.length === allCase.length) {
                    // Show "All" tag if all are selected
                    return (
                      <Chip
                        label="All"
                        deleteIcon={
                          <SvgIcon
                            sx={{
                              color: "#686DFC",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: "#686DFC",
                              }}
                            />
                          </SvgIcon>
                        }
                        sx={{
                          height: "25px",
                          backgroundColor: "#686DFC26",
                          color: "#A6A7C1",
                          textTransform: "capitalize",
                          borderRadius: "2px",
                          width: "45px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.875rem",
                        }}
                      />
                    );
                  }

                  const extraCount = value.length - maxVisibleChips;
                  return (
                    <>
                      {value.slice(0, maxVisibleChips).map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option?.title}
                          deleteIcon={
                            <SvgIcon
                              sx={{
                                color: "#686DFC",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              <ClearIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#686DFC",
                                }}
                              />
                            </SvgIcon>
                          }
                          sx={{
                            backgroundColor: "#686DFC26",
                            color: "#A6A7C1",
                            height: "25px",
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            width: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.875rem",
                          }}
                        />
                      ))}
                      {extraCount > 0 && (
                        <Chip
                          label={`${extraCount} more`}
                          sx={{
                            backgroundColor: "#686DFC26",
                            color: "#A6A7C1",
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            width: "45px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.875rem",
                          }}
                        />
                      )}
                    </>
                  );
                }}
                noOptionsText="No matter found"
              />
            </FormControl>
          </Grid>
          {/* category */}
          <Grid item sm={3}>
            <FormControl fullWidth variant="outlined" size="small" error={formik.touched.category && Boolean(formik.errors.category)} sx={styles?.input}>
              <InputLabel
                id="category-label"
                sx={{
                  color: "#C8C9DB",
                  "&.Mui-focused": { color: "#C8C9DB" },
                }}
              >
                Category
              </InputLabel>
              <Select
                labelId="category-label"
                id="category"
                name="category"
                label="Category"
                value={formik.values.category}
                onBlur={formik.handleBlur}
                onChange={handleCategoryChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 200,
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: "#686DFC",
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select category</em>
                </MenuItem>
                {allCategory?.map((value, index) => (
                  <MenuItem value={value.values} key={index}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* person */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.fillingParty && Boolean(formik.errors.fillingParty)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64", // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C8C9DB", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#A6A7C1", // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C8C9DB",
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: "#C8C9DB",
                    "&.Mui-focused": {
                      color: "#A6A7C1", // Change label color when focused
                    },
                  }}
                >
                  Persons
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="Persons"
                  value={formik.values.fillingParty || ""} // Ensure this is the correct field
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const filterData = allPersons.find((v) => v.personsId === e.target.value)?.witnessData;

                    formik.setFieldValue("witnessData", filterData);
                    formik.setFieldValue("fillingParty", selectedValue);
                    formik.setFieldValue("personsId", selectedValue);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                          },
                        },
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    // Check if a person is selected
                    if (selected) {
                      const selectedPerson = allPersons?.find((person) => person.personsId === selected);
                      return selectedPerson ? selectedPerson.personName : <em>Select person</em>;
                    }
                    return <em>Select person</em>; // Default placeholder
                  }}
                  sx={{
                    // Add these styles to handle long text
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select person</em>
                  </MenuItem>
                  {allPersons?.map((row, index) => (
                    <MenuItem key={index} value={row.personsId}>
                      {`${row?.personName}`}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && <FormHelperText>{formik.errors.fillingParty}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(formik.values.category) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched?.type && Boolean(formik.errors?.type)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#A6A7C1",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C8C9DB",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C8C9DB",
                  },
                }}
              >
                <InputLabel
                  id="type-label"
                  sx={{
                    color: "#C8C9DB",
                    // Change label color
                    "&.Mui-focused": {
                      color: "#A6A7C1", // Change label color when focused
                    },
                  }}
                >
                  {formik.values.category === "person" ? "Role" : "Type"}
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  label="Type"
                  autoComplete="off"
                  name="type"
                  placeholder="type"
                  value={formik.values?.type}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        // maxWidth: "300px", // Set max width for the dropdown container
                        maxHeight: 300, // Limit dropdown height
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          // maxWidth: "260px", // Set max width for each item
                          whiteSpace: "nowrap", // Prevents line breaks
                          overflow: "hidden", // Hide overflowed content
                          textOverflow: "ellipsis", // Add ellipsis when content overflows
                          "&:hover": {
                            backgroundColor: "#686DFC26", // Hover background color
                            color: "#686DFC", // Hover text color,
                          },
                        },
                      },
                    },
                    MenuProps,
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select type</em>
                  </MenuItem>
                  {allType?.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row.length >= 30 ? row.substring(0, 25) + "..." : row}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.type && formik.errors.type && <FormHelperText>{formik.errors.type}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  backgroundColor: formik.values.category === "client" ? "#2B2C40" : "",
                }}
              >
                <InputLabel
                  id="filter-label"
                  sx={{
                    color: "#C8C9DB",

                    "&.Mui-focused": { color: "#C8C9DB" },
                    "&.Mui-disabled": { color: "#C8C9DB" },
                  }}
                >
                  Filter 1
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled
                  label="Filter 1"
                  value={formik.values.personsId || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    "&.Mui-disabled": {
                      color: "#4A4C64",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#C8C9DB",
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      color: "#C8C9DB",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                          },
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
          )}
          {/* Item Dropdown */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl fullWidth variant="outlined" size="small" error={formik.touched.items && Boolean(formik.errors.items)} sx={styles?.input}>
                <Autocomplete
                  size="small"
                  multiple
                  disableClearable
                  id="items-autocomplete"
                  options={userFile} // The array of files with fileName and fileUrl
                  getOptionLabel={(option) => option?.fileName || ""} // Display fileName in dropdown
                  value={formik.values.items ? userFile.filter((file) => formik.values.items.includes(file.fileUrl)) : []}
                  onChange={(event, newValue) => {
                    const fileUrls = newValue.map((item) => item?.fileUrl);
                    formik.setFieldValue("items", fileUrls);
                  }}
                  disableCloseOnSelect // Keep dropdown open while selecting multiple options
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Items"
                      error={formik.touched.items && Boolean(formik.errors.items)}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        style: {
                          color: "#C8C9DB",
                        },
                      }}
                      sx={{
                        color: "#C8C9DB",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          width: "100%",
                          color: "#A6A7C1",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#4A4C64",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#C8C9DB",
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#C8C9DB",
                        },
                        "&.Mui-focused": {
                          color: "#C8C9DB",
                        },
                      }}
                    />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 300, // Adjust as needed
                        overflowY: "auto",
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option?.fileName} // Display fileName in tags
                        deleteIcon={
                          <SvgIcon
                            sx={{
                              color: "#686DFC",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: "#686DFC",
                              }}
                            />
                          </SvgIcon>
                        }
                        sx={{
                          backgroundColor: "#686DFC26",
                          color: "#A6A7C1",
                          textTransform: "capitalize",
                          borderRadius: "2px",
                          width: "70px",
                          whiteSpace: "nowrap", // Prevent the text from wrapping
                          overflow: "hidden", // Hide any overflow content
                          textOverflow: "ellipsis", // Add ellipsis for long text
                          fontSize: "0.875rem",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        color: "#C8C9DB",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={selected}
                        sx={{
                          color: "#A6A7C1",
                          width: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          "&.Mui-checked": {
                            color: "#686DFC",
                            backgroundColor: "transparent",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.2rem", // Adjust this value for width/height
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: "14px" }} paddingLeft={1}>
                        {" "}
                        {option.fileName}
                      </Typography>
                    </li>
                  )}
                  sx={{
                    color: "#C8C9DB",
                    "& .MuiOutlinedInput-root": {
                      color: "#C8C9DB",
                      "&.Mui-focused fieldset": {
                        borderColor: "#C8C9DB",
                      },
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "#C8C9DB",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#C8C9DB",
                    },
                    "& .MuiListSubheader-root, .MuiAutocomplete-option": {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                    },
                  }}
                />
                {formik.touched.items && formik.errors.items && <FormHelperText>{formik.errors.items}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(formik.values.category || chatHistory?.moduleName) && (formik.values?.type || chatHistory?.type) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.fillingParty && Boolean(formik.errors.fillingParty)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64", // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C8C9DB", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#A6A7C1", // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C8C9DB",
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: "#C8C9DB",
                    "&.Mui-focused": {
                      color: "#A6A7C1", // Change label color when focused
                    },
                  }}
                >
                  Witness Name
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="witness name"
                  value={formik.values.personsId || chatHistory?.person_id || formik.values.fillingParty}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const filterData = allPersons?.find((v) => v.personsId === e.target.value)?.witnessData;
                    formik.setFieldValue("fillingParty", e.target.value);
                    formik.setFieldValue("personsId", e.target.value);
                    formik.setFieldValue("witnessData", filterData);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select witness name</em>
                  </MenuItem>
                  {allPersons?.map((row, index) => (
                    <MenuItem key={index} value={row.personsId}>
                      {`${row?.personName}`}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && <FormHelperText>{formik.errors.fillingParty}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  color: "#C8C9DB",
                  backgroundColor: formik.values.category === "client" ? "#2B2C40" : "",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C8C9DB",
                  },
                }}
              >
                <InputLabel
                  id="filter-label"
                  sx={{
                    color: "#C8C9DB",
                    "&.Mui-focused": { color: "#C8C9DB" },
                    "&.Mui-disabled": { color: "#C8C9DB" }, // Set label color when disabled
                  }}
                >
                  Filter 2
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled={true}
                  label="filter 2"
                  value={formik.values.filter}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64", // Normal border color
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64", // Set border color when disabled
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#C8C9DB", // Normal icon color
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      color: "#C8C9DB", // Set icon color when disabled
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                          },
                        },
                      },
                    },
                  }}
                ></Select>
              </FormControl>
            </Grid>
          )}

          {/* Autocomplete */}

          <Grid item sm={3}>
            <FormControl fullWidth sx={{ ...styles?.inpuDisable, backgroundColor: formik.values.category === "client" || formik?.values?.category === "medical" ? "#2B2C40" : "" }}>
              <Autocomplete
                sx={{ color: "#C8C9DB" }}
                id="sources"
                disabled={formik.values.category === "client" || formik.values.category === "medical"}
                disableClearable={true}
                options={[1, 2, 3, 4, 5, 6, 7, 8]}
                getOptionLabel={(option) => `${option}`}
                value={formik.values.sources || 4}
                onChange={(event, value) => {
                  formik.setFieldValue("sources", value);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Number of Sources"
                    error={formik.touched.sources && Boolean(formik.errors.sources)}
                    sx={{
                      color: "#C8C9DB",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        width: "100%",
                        color: "#A6A7C1", // Set text color
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#4A4C64",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: formik.values.category === "client" || formik?.values?.category === "medical" ? "" : "#A6A7C1",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#4A4C64", // Normal border color
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#A6A7C1",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#C8C9DB",
                      },
                      "&.Mui-focused": {
                        color: "#C8C9DB", // Change label color when focused
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option} style={{ color: "#C8C9DB" }}>
                    {option}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                size="small"
                label="Max Number of Tokens"
                name="maxTokens"
                disabled={formik.values.category === "medical"}
                value={formik.values.category === "medical" ? 1000 : formik.values.maxTokens || 1000}
                // value={1000}
                onChange={formik.handleChange} // Ensure onChange is from formik
                sx={{
                  color: "#C8C9DB",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    width: "100%",
                    backgroundColor: formik.values.category === "medical" ? "#2B2C40" : "",

                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C8C9DB",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4A4C64",
                    },
                    "&.Mui-disabled .MuiInputBase-input": {
                      color: "#A6A7C1 !important",
                      "-webkit-text-fill-color": "#A6A7C1",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C8C9DB",
                  },
                  "&.Mui-focused": {
                    color: "#C8C9DB", // Change label color when focused
                  },
                  "&.Mui-disabled": {
                    color: "#A6A7C1", // Text color for the disabled state
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "#C8C9DB",
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default QuerieDropdwon;
