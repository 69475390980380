import { Divider, Grid, Typography, Box, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const AddCompanyModel = ({ setAllClient, formik, setCustomCompanyName, closeModal, customCompanyName }) => {
  const [newCompany, setCompany] = useState(customCompanyName);

  const handleClose = () => {
    setCustomCompanyName(newCompany);
    setAllClient((e) => [...e, newCompany]);
    formik.setFieldValue("companyName", newCompany);
    closeModal();
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // Handle input change
  const handleChange = (e) => {
    const value = e.target.value;
    setCompany(capitalizeFirstLetter(value));
  };

  return (
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h5"
          sx={{
            color: "#E6E7F2",
            fontFamily: "Public Sans ,sans-serif",
            textTransform: "capitalize",
          }}
        >
          Add Company
        </Typography>
      </Grid>
      <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
        <CloseIcon sx={{ color: "#E6E7F2", cursor: "pointer" }} />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ backgroundColor: "#4A4C64", marginY: "10px" }} />
      </Grid>
      {/* here is form  */}
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative", width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoComplete="given-companyName"
              name="companyName"
              id="companyName"
              label="Company Name"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              value={newCompany}
              onChange={handleChange}
              // onChange={(e) => setCompany(e.target.value)}
              sx={{
                // width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray", // Border color on hover
                  },
                  "&::placeholder": {
                    textTransform: "capitalize", // Capitalize the placeholder text
                    color: "#C8C9DB", // Placeholder text color
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB", textTransform: "capitalize" },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                  textTransform: "capitalize",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* button */}
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {" "}
            <Button
              onClick={closeModal}
              variant="contained"
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "Public Sans ,sans-serif",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              save changes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AddCompanyModel;
