import React from "react";

import { theme } from "./Theme";
import { Grid, FormControl, TextField, Paper, Chip, SvgIcon, Box, FormHelperText, Typography, Autocomplete, InputLabel, MenuItem, Button, Select } from "@mui/material";
import { Note } from "@mui/icons-material";

const LabeledTextField = ({ formik, name, label, placeholder, required = false, error, helperText, inputStyles = {}, labelStyles = {}, ...props }) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Typography
        variant="body1"
        sx={{
          marginBottom: "4px",
          ...labelStyles, // Custom styles for label

          color: formik.touched[name] && Boolean(formik.errors[name]) ? formik.theme?.palette?.secondary?.dangerMain : formik.theme?.palette?.secondary?.main,
          fontFamily: theme?.typography?.fontFamily,
        }}
      >
        {label}
        {required && (
          <span
            style={{
              color: formik.touched[name] && Boolean(formik.errors[name]) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}{" "}
        {/* Space between label and asterisk */}
      </Typography>

      <TextField
        name={name}
        required={required}
        variant="outlined"
        size="small"
        placeholder={placeholder}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[name]}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={helperText || (formik.touched[name] && formik.errors[name])}
        sx={{ ...inputStyles }} // Custom styles for input field
        InputProps={{
          style: {
            color: theme?.palette?.primary?.main,
          },
        }}
        {...props} // Additional props
      />
    </FormControl>
  );
};

export default LabeledTextField;

export const CustomSelectDropDown = ({
  label,
  name,
  value,
  error,
  helperText,
  options,
  formik,
  theme,
  required = false,
  inputStyles = {},
  placeholder,
  ...props // Any other props you want to pass to the Select component
}) => {
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={error}
      // required 
      sx={{
        ...inputStyles, // Apply any passed styles here
      }}
    >
      <Select
        labelId={`${name}-label`}
        id={name}
        autoComplete="off"
        name={name}
        value={value}
        placeholder={placeholder}
        displayEmpty
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "#323249",
              color: theme?.palette?.secondary?.main,
              maxHeight: 200,
              overflowY: "auto",
              "& .MuiMenuItem-root": {
                "&:hover": {
                  backgroundColor: "#686DFC26", // Hover background color
                  color: "#686DFC", // Hover text color
                },
              },
            },
          },
        }}
        {...props} // Spread any other props
      >
        <MenuItem value="" disabled>
          <em>Select {label}</em>
        </MenuItem>
        {options.map((value, index) => (
          <MenuItem value={value} key={index}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

//  state field
export const StateSelect = ({
  id = "state",
  name = "state",

  placeholder,
  options = [],
  formik,
  noOptionsText = "No state found",
  inputPersonStyles,
  theme,
  required,
  disableClearable = true,
  size = "small",
  customOnChange, // Optional custom onChange handler
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        options={options}
        disableClearable={disableClearable}
        getOptionLabel={(option) => option}
        value={formik.values[name] || ""}
        onChange={(event, newValue) => {
          formik.setFieldValue(name, newValue);
          if (customOnChange) customOnChange(newValue); // Call the custom onChange if provided
        }}
        onBlur={formik.handleBlur}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              backgroundColor: "#323249",
              "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-hover": {
                bgcolor: "red",
              },
              color: theme?.palette?.secondary?.main,
              maxHeight: 300, // Adjust as needed
              overflowY: "auto",
            }}
          >
            {children}
          </Paper>
        )}
        ListboxProps={{
          sx: {
            maxHeight: 200,
            overflowY: "auto",
            "& .MuiAutocomplete-option": {
              "&:hover": {
                backgroundColor: "#686DFC26",
                color: "#686DFC",
              },
            },
            "& .MuiAutocomplete-noOptions": {
              color: "#FF7799", // Customize the color of the noOptionsText
            },
          },
        }}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            id={id}
            name={name}
            required={required}
            variant="outlined"
            placeholder={placeholder}
            size={size}
            autoComplete={name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            sx={inputPersonStyles?.state(formik)}
            InputLabelProps={{
              style: {
                color: formik.touched[name] && Boolean(formik.errors[name]) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
              },
            }}
          />
        )}
      />
    </>
  );
};

//     <FormControl fullWidth>
//       {/* Dynamic Label */}
//       <Typography
//         variant="body1"
//         sx={{
//           marginBottom: "4px",
//           color: theme?.palette?.secondary?.main, // Use theme color dynamically
//         }}
//       >
//         {label}
//       </Typography>

//       {/* Dynamic TextField */}
//       <TextField
//         fullWidth
//         multiline
//         rows={rows}
//         name={name}
//         variant="outlined"
//         value={formik.values?.[name]}
//         onChange={formik.handleChange}
//         onBlur={formik.handleBlur}
//         error={formik.touched?.[name] && Boolean(formik.errors?.[name])}
//         helperText={formik.touched?.[name] && formik.errors?.[name]}
//         placeholder={placeholder}
//          sx={
//         inputPersonStyles?.note,
//         }
//         InputLabelProps={{
//           style: { color: theme?.palette?.secondary?.main },
//         }}
//         InputProps={{
//           style: {
//             color: theme?.palette?.primary?.main,
//           },
//         }}
//         {...props}
//       />
//     </FormControl>
//   );
// };

export const NoteInput = ({ label = "Note", placeholder = "Enter your note here...", rows = 6, name = "note", formik, inputStyles = {}, theme, required = false, ...props }) => {
  return (
    <FormControl fullWidth>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "4px",
          color: theme?.palette?.secondary?.main, // Dynamic label color
          fontFamily: theme?.typography?.fontFamily,
        }}
      >
        {label} {required && "*"}
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={rows}
        name={name}
        variant="outlined"
        value={formik.values?.[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched?.[name] && Boolean(formik.errors?.[name])} // Handle errors
        helperText={formik.touched?.[name] && formik.errors?.[name]} // Show error text
        placeholder={placeholder}
        required={required} // Ensure required prop works
        sx={{
          ...inputStyles, // Spread input styles
        }}
        InputLabelProps={{
          style: { color: theme?.palette?.secondary?.main }, // Dynamic label color
        }}
        InputProps={{
          style: {
            color: theme?.palette?.primary?.main, // Dynamic input text color
          },
        }}
        {...props} // Spread additional props
      />
    </FormControl>
  );
};
