import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import CompanyIcon from "../Components/Icons/Company";
import PersonTabForm from "../Components/Forms/PersonForm/Person";
import CompanyTabForm from "../Components/Forms/PersonForm/Company";

// TabPanel component that switches between tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// PropTypes for TabPanel
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired, // Ensure index is a string to match the value
  value: PropTypes.string.isRequired, // Ensure value is a string
};

// Accessibility props for tabs
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function TabsConstants({ formik }) {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    formik.setFieldValue("type", newValue); // Update formik field value
  };

  const tabsCustom = {
    color: "#A6A7C1",
    fontSize: { xs: "12px", xl: "15px" },
    "&:hover, &:focus": {},
    "&.Mui-selected": {
      color: "#FFFFFF",
    },
    "&.Mui-disabled": {
      color: "#FFFFFF",
      backgroundColor: "#686DFC",
    },
    fontFamily: "Public Sans",
    fontWeight: "400",
    borderRadius: "5px",
    border: "1px solid #A6A7C1 ",
  };

  return (
    <Box>
      <Box sx={{ width: 400, boxShadow: 0 }}>
        <AppBar position="static" sx={{ background: "transparent", boxShadow: 0, width: "70%" }}>
          <Tabs
            value={formik?.values?.type} // Use formik value for the selected tab
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: "none" }, // Hide the default tab indicator
            }}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {/* Person Tab */}
            <Tab
              value={"person"}
              sx={{ ...tabsCustom, marginRight: "10px" }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PersonIcon
                    sx={{
                      marginRight: "5px",
                      fontSize: "18px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Public Sans",
                      fontWeight: "400",
                      fontSize: { xs: "12px", xl: "15px" },
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    Person
                  </Typography>
                </Box>
              }
              {...a11yProps("person")}
            />
            {/* Company Tab */}
            <Tab
              value={"company"}
              sx={tabsCustom}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <CompanyIcon
                    sx={{
                      marginRight: "5px",
                      fontSize: "18px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Public Sans",
                      fontWeight: "400",
                      fontSize: { xs: "12px", xl: "15px" },
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    Company
                  </Typography>
                </Box>
              }
              {...a11yProps("company")}
            />
          </Tabs>
        </AppBar>
      </Box>
      {/* Render Person form when "person" tab is selected */}
      <TabPanel value={formik?.values?.type} index="person" dir={theme.direction}>
        <PersonTabForm formik={formik} />
      </TabPanel>
      {/* Render Company form when "company" tab is selected */}
      <TabPanel value={formik?.values?.type} index="company" dir={theme.direction}>
        <CompanyTabForm formik={formik} />
      </TabPanel>
    </Box>
  );
}
