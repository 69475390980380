import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SvgIcon, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import PopupModel from "../../Components/Modal";
import AddCompanyModel from "../Modal/PersonsModel/AddCompanyModel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { state } from "../../constant/ConstantStateData";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import { inputPersonStyles } from "../../pages/Persons/style";
import { theme } from "../../constant/Theme";
import LabeledTextField, { CustomSelectDropDown, NoteInput } from "../../constant/ConstantInputField";
import ClearIcon from "@mui/icons-material/Clear";
import TabsConstants from "../../constant/TabsConstants";

const baseSchema = Yup.object({
  email: Yup.string().email("Invalid email address").nullable().notRequired(), // Optional and allows null

  phone: Yup.string()
    .nullable()
    .notRequired()
    .test("phone", "Phone number is not valid", function (value) {
      if (!value || value === "") {
        return true; // Skip validation if the value is empty or null
      }
      return /^(\+?\d{1,4}[\s-]?)?(?!0+\s+,?$)\d{10,15}$/.test(value); // Apply regex validation only if value exists
    }),

  address: Yup.string()
    .nullable()
    .notRequired()
    .test("address", "Address must be at least 10 characters.", function (value) {
      if (!value || value === "") {
        return true; // Skip validation if the value is empty or null
      }
      return value.length >= 10; // Check only if value exists
    }),

  city: Yup.string()
    .nullable()
    .notRequired()
    .test("city", "City must contain only letters and spaces.", function (value) {
      if (!value || value === "") {
        return true; // Skip validation if the value is empty or null
      }
      return /^[A-Za-z\s]+$/.test(value); // Apply regex validation only if value exists
    }),

  state: Yup.string().nullable().notRequired(),

  zipcode: Yup.string()
    .nullable()
    .notRequired()
    .test("zipcode", "The zip code must be exactly 5 digits long.", function (value) {
      if (!value || value === "") {
        return true; // Skip validation if the value is empty or null
      }
      return /^\d{5}$/.test(value); // Apply regex validation only if value exists
    }),
});

// Define schema for 'person' type fields
const personFields = Yup.object({
  firstName: Yup.string().min(3, "First name must be at least 3 characters.").required("First name is required for a person"),
  lastName: Yup.string().min(3, "Last name must be at least 3 characters.").required("Last name is required for a person"),
  // gender: Yup.string(),
});

// Function to dynamically choose schema based on 'type'
export const validationSchemaStepOne = Yup.object({
  type: Yup.string().required("Type is required"),
}).shape({
  ...baseSchema.fields,
  firstName: Yup.string().when("type", {
    is: "person",
    then: () => Yup.string().min(3, "First name must be at least 3 characters.").required("Required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  lastName: Yup.string().when("type", {
    is: "person",
    then: () => Yup.string().min(3, "Last name must be at least 3 characters.").required("Required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  // gender: Yup.string().when("type", {
  //   is: "person",
  //   then: () => Yup.string().required("Required"),
  //   otherwise: () => Yup.string().notRequired(),
  // }),
});

export const validationSchemaStepTwo = Yup.object({

  caseId: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  policyLimit: Yup.string(),
  policyNumber: Yup.string(),
  note: Yup.string(),
});
let stateNames = state;

export const PersonRenderStep = (activeStep, formik) => {
  const [openCase, setOpenCase] = useState(false);
  const handleOpen = () => setOpenCase(true);
  const handleClose = () => setOpenCase(false);

  const [customCompanyName, setCustomCompanyName] = useState("");
  const { user } = useSelector((state) => state?.reducer);
  const [allClient, setAllClient] = useState([]);
  const [emailError, setEmailError] = useState();
  const [allCase, setAllCase] = useState([]);
  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "caseDropdown", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "getClients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        let result = response?.data?.data.map((v) => v.companyName).filter((name) => name && name.trim() !== ""); // Filter out empty names

        if (result.length === 0) {
          result = ["N/A"]; // Set to "N/A" if no valid data is returned
        }

        const currentCompanyName = formik.values.companyName;
        if (currentCompanyName && currentCompanyName.trim() !== "") {
          setAllClient([...result, currentCompanyName]);
        } else {
          setAllClient(result);
        }
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClient(["N/A"]); // Set to "N/A" if the API call fails
    }
  };

  useEffect(() => {
    getAllCase();
    getAllClients();
  }, []);

  const MenuProps = {
    // component: CustomMenu,

    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };

  console.log("formik", formik);

  switch (activeStep) {
    case 0:
      return (
        <>
          <TabsConstants formik={formik} />
        </>
      );
    case 1:
      return (
        <>
          <Grid container spacing={3}>
           

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color: formik.touched.caseId && Boolean(formik.errors?.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Matters *
              </Typography>
              <FormControl required variant="outlined" size="small" fullWidth error={formik.touched.caseId && Boolean(formik.errors.caseId)} sx={inputPersonStyles?.matterName(formik)}>
                <Autocomplete
                  id="case"
                  options={allCase}
                  disableClearable={true}
                  getOptionLabel={(option) => option.title}
                  autoComplete
                  size="small"
                  includeInputInList
                  value={allCase.find((option) => option._id === formik.values.caseId) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("case", newValue?._id || "");
                    formik.setFieldValue("caseId", newValue?._id || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      required
                      fullWidth
                      error={formik.touched.caseId && Boolean(formik.errors.caseId)}
                      helperText={formik.touched.caseId && formik.errors.case ? formik.errors.caseId : ""}
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.secondary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={inputPersonStyles?.TextField}
                    />
                  )}
                  PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: theme?.palette?.secondary?.main }} />}
                  ListboxProps={{
                    sx: inputPersonStyles.listbox,
                  }}
                  noOptionsText="No matters found"
                />

                {allCase.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No matter is added.</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{ color: theme?.palette?.secondary?.main, mb: "4px" }} // Optional styles for the label
              >
                Role *
              </Typography>
              <CustomSelectDropDown
                label="Role"
                name="role"
                value={formik.values.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role ? formik.errors.role : ""}
                options={["Healthcare Provider", "Insurance Adjuster"]}
                formik={formik}
                theme={theme}
                inputStyles={inputPersonStyles?.role(formik)}
                placeholder={"Select Role"}
              />
            </Grid>
            {formik.values.role === "Insurance Adjuster" && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme?.palette?.secondary?.main, mb: 1 }} // Optional styles for the label
                  >
                    Insurance Type
                  </Typography>
                  <CustomSelectDropDown
                    label="Insurance Type"
                    name="insuranceType"
                    value={formik.values.insuranceType}
                    error={formik.touched.role && Boolean(formik.errors.insuranceType)}
                    helperText={formik.touched.role ? formik.errors.insuranceType : ""}
                    options={["Liability Adjuster", "Property Damage Adjuster", "UM Adjuster"]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Insurance Type"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyNumber" label="Policy number" placeholder="Enter Policy number" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyLimit" label="Policy limit" placeholder="Enter Policy Limit" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
              </>
            )}

            {formik.values.role === "Healthcare Provider" && (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{ color: theme?.palette?.secondary?.main, mb: 1 }} // Optional styles for the label
                >
                  Physician Type
                </Typography>
                <CustomSelectDropDown
                  label="physician Type"
                  name="physicianType"
                  value={formik.values.physicianType}
                  error={formik.touched.role && Boolean(formik.errors.physicianType)}
                  helperText={formik.touched.role ? formik.errors.physicianType : ""}
                  options={["Treating Physician", "Non-treating Physician"]}
                  formik={formik}
                  theme={theme}
                  inputStyles={inputPersonStyles?.insuranceType(formik)}
                  placeholder={"Select Physician Type"}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <NoteInput
                formik={formik} // Pass formik to manage form state and validation
                label="Note " // Custom label
                placeholder="Add your notes here" // Custom placeholder
                name="note" // Field name for formik to manage the state
                rows={6} // Number of rows
                theme={theme} // Custom theme for color and styles
                inputStyles={inputPersonStyles?.note} // Custom styles for the input
              />
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
