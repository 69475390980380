import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import PersonForm from "../../Components/Forms/PersonForm";
import PersonformIcon from "../../Components/Icons/PersonformIcon";
import FeedIcons from "../../Components/Icons/FeedIcon";
import { theme } from "../../constant/Theme";
import PersonMatterForm from "../../Components/Forms/PersonMatter";
const steps = [
  { text: "Basic Details", label: "Person information" },
  { text: "Other Details", label: "Add more details" },
];
const AddNewPersonMatter = () => {
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color={theme?.palette?.primary?.main}>
              Persons /
              <Box component="span" color={theme?.palette?.primary?.secondaryheading}>
                {" "}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: theme?.palette?.background?.paper,
                color: "lightgray",
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stepper
                    activeStep={activeStep}
                    connector={
                      <ArrowForwardIosIcon
                        sx={{
                          display: { xs: "none", sm: "block" },
                          marginLeft: "15px",
                          marginRight: "15px",
                        }}
                      />
                    }
                  >
                    {steps.map((step, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      labelProps.optional = (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#8A8BA1",
                            fontFamily: theme?.typography?.fontFamily,
                            fontSize: "14px",
                          }}
                        >
                          {step.label}
                        </Typography>
                      );
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          key={index}
                          {...stepProps}
                          sx={{
                            display: activeStep == index ? { xs: "block", sm: "block" } : { xs: "none", sm: "block" },
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "white",
                            },
                            "& .MuiStepLabel-root .Mui-disabled": {
                              color: "#C8C9DB",
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: activeStep == index ? theme?.palette?.secondary?.backgroundColor : theme?.palette?.secondary?.main,
                            },
                            "& .Mui-completed": { color: "white" },
                            " & .MuiStepLabel-label ": {
                              color: "white",
                            },
                          }}
                        >
                          <StepLabel
                            {...labelProps}
                            icon={
                              step.text == "Basic Details" ? (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "8px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    backgroundImage: activeStep >= index ? theme?.palette?.primary?.iconColor : theme?.palette?.secondary?.inputColor,
                                  }}
                                >
                                  <FeedIcons color="white" />
                                </Box>
                              ) : (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "8px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    background: activeStep >= index ? theme?.palette?.primary?.iconColor : theme?.palette?.secondary?.inputColor,
                                  }}
                                >
                                  <PersonformIcon />
                                </Box>
                              )
                            }
                          >
                            {step.text}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Grid item xs={12} style={{ paddingTop: "12px" }}>
                    <Divider sx={{ backgroundColor: theme?.palette?.divider }} />
                  </Grid>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PersonMatterForm activeStep={activeStep} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} steps={steps} isStepOptional={isStepOptional} />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewPersonMatter;
