import { Divider, Grid, Typography, TextField, Button, Autocomplete, Select, MenuItem, Box, FormControl, InputLabel, FormHelperText, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const DepositionModel = ({ closeModal, filter, setFilter }) => {
  const user = useSelector((state) => state?.reducer?.user);
  // persons api
  const [allPerson, setAllPerson] = useState([]);
  const [isCleardisable, setisCleardisbale] = useState(filter && typeof filter == "object" && Object.keys(filter).length > 0);
  const [allCase, setAllCase] = useState([]);
  const [allType, setAllType] = useState([]);
  // for hidden the filter matter
  const { matter } = useMultiFileUpload();

  const getAllCase = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "depositionFilter", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.uniqueCases);
        setAllPerson(response?.data?.uniqueWitnesses);
        setAllType(response?.data?.uniqueTypes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllCase();
  }, []);
  const formik = useFormik({
    initialValues: {
      caseTitle: filter?.caseTitle || "",
      witnessId: filter?.witnessId || "",
      witness: filter?.witness || "",
      type: filter?.type || "",
      date: filter?.date || 0,
    },
    onSubmit: async (values, { resetForm }) => {
      setFilter(values);
      closeModal();
    },
  });

  const handleClearFilter = () => {
    setFilter({});
    closeModal();
  };
  const timestampToDateInputString = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const today = dayjs();
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={11} sm={11} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: theme?.palette?.primary?.tertiaryHeading, fontFamily: theme?.typography?.fontFamily }}>
            Filters
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }} onClick={closeModal}>
          <CloseIcon sx={{ color: theme?.palette?.primary?.tertiaryHeading, cursor: "pointer" }} />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ backgroundColor: "#4A4C64", marginY: "10px" }} />
        </Grid>

        {/* filter input */}
      </Grid>
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          {!matter && (
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.secondary?.main,
                  marginBottom: "4px",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Matter
              </Typography>
              <Autocomplete
                id="caseTitle"
                options={allCase}
                fullWidth
                size="small"
                disableClearable
                getOptionLabel={(option) => option?.title || ""}
                isOptionEqualToValue={(option, value) => option?.title === value?.title}
                value={allCase.find((option) => option?.title === formik.values.caseTitle) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue("caseTitle", newValue?.title || "");
                  formik.setFieldValue("caseId", newValue?._id || ""); // Update formik field for caseId
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Matter"
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={formik.touched.caseId && Boolean(formik.errors.caseId)}
                    InputLabelProps={{
                      sx: {
                        color: "#C8C9DB",
                        "&.Mui-focused": {
                          color: "#C8C9DB",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        color: "#A6A7C1",
                        "& fieldset": {
                          borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? "#D32F2F" : "#4A4C64",
                        },
                        "&:hover fieldset": {
                          borderColor: formik.touched.caseId && Boolean(formik.errors.caseId) ? "#D32F2F" : "#C8C9DB",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: "#C8C9DB" }} />}
                ListboxProps={{
                  sx: {
                    maxHeight: 300,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                  },
                }}
                noOptionsText="No matters found"
              />
            </Grid>
          )}

          {/* witness */}
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.secondary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Witness name{" "}
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              error={formik?.touched?.witness && Boolean(formik?.errors?.witness)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.witness && Boolean(formik?.errors?.witness) ? "#D32F2F" : "#4A4C64",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.witness && Boolean(formik?.errors?.witness) ? "#D32F2F" : "#4A4C64",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik?.touched?.witness && Boolean(formik?.errors?.witness) ? "#D32F2F" : "#4A4C64",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <Select
                labelId="witness-label"
                id="witness"
                autoComplete="off"
                name="witness"
                label="Witness name"
                value={formik.values.witnessId}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("witness", e.target.value);
                  formik.setFieldValue("witnessId", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
                sx={{
                  width: "100%",
                  "& .MuiSelect-select": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                {allPerson
                  ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((row) => (
                    <MenuItem key={row?._id} value={row?._id}>
                      {row?.firstName} {row?.lastName}
                    </MenuItem>
                  ))}
              </Select>
              {formik?.touched?.witness && formik?.errors?.witness && <FormHelperText>{formik?.errors?.witness}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <FormControl fullWidth>
              <Typography
                variant="body1"
                sx={{
                  color: theme?.palette?.secondary?.main,
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} size="small">
                  <DatePicker
                    value={formik?.values?.date ? dayjs(new Date(formik?.values?.date).toString()) : null}
                    autoComplete="given-date"
                    slotProps={{ textField: { size: "small" } }} //for height of date input
                    error={formik.touched.date && formik.errors?.date}
                    onChange={(date) => {
                      if (date && dayjs(date).isAfter(today)) {
                        formik.setFieldValue("date", today.valueOf());
                      } else {
                        formik.setFieldValue("date", date ? new Date(date).getTime() : "");
                      }
                    }}
                    maxDate={today}
                    helperText={formik.touched?.date && formik.errors?.date}
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        color: "#C8C9DB",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#4A4C64", // Normal border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#4A4C64", // Border color on hover
                        },
                        "& .MuiInputBase-input": {
                          color: "#C8C9DB !important",
                        },
                      },
                      "& .MuiInputLabel-root.MuiInputLabel-formControl": {
                        color: "#C8C9DB", // Error label color
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#A6A7C1", // Yellow border color on focus
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#C8C9DB",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#C8C9DB",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#C8C9DB",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.secondary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Type
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <Select
                labelId="type-label"
                id="case"
                autoComplete="off"
                name="type"
                label="type"
                value={formik.values?.type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                  MenuProps,
                }}
              >
                {allType?.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* button */}
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",

              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <Button
              onClick={() => handleClearFilter()}
              variant="contained"
              disabled={!isCleardisable}
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: "#4A4C64",
                  // color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              clear filter
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Apply filter{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DepositionModel;
