import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
 import BalanceIcon from "../../Components/Icons/BalanceIcon";
import UpdateIssueForm from "../../Components/Forms/IssueCaseForm/UpdateIssuseForm";
import FeedIcons from "../../Components/Icons/FeedIcon";
import useScrollToRef from "../../hooks/useScrollToRef";
const steps = [
  { text: "Basic Details", label: "Issue information" },
  { text: "Case 1", label: "Case 1 details" },
  { text: "Case 2", label: "Case 2 details" },
  { text: "Case 3", label: "Case 3 details" },
];

const EditIssuesPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const formRef = useRef(null);
  const navbarRef = useRef(null);
  const scrollToRef = useScrollToRef(navbarRef);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to top
    }
  }, [activeStep]);
  const handleBack = () => {
    scrollToRef();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    scrollToRef();

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    scrollToRef();
  };

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography ref={navbarRef} variant="h5" sx={{ color: "#A6A7C1" }}>
              Issues /
              <Box component="span" sx={{ color: "#F2F3FB" }}>
                {" "}
                Edit Issue
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#2B2C40",
                color: "lightgray",
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stepper
                    activeStep={activeStep}
                    connector={
                      <ArrowForwardIosIcon
                        sx={{
                          display: { xs: "none", sm: "block" },
                          marginLeft: "15px",
                          marginRight: "15px",
                          width: { sm: "15px", md: "24px" },
                          height: { sm: "15px", md: "24px" },
                        }}
                      />
                    }
                  >
                    {steps.map((step, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      labelProps.optional = (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#8A8BA1",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {step.label}
                        </Typography>
                      );
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          key={index}
                          {...stepProps}
                          sx={{
                            display: activeStep == index ? { xs: "block", sm: "block" } : { xs: "none", sm: "block" },
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "white",
                            },
                            "& .MuiStepLabel-root .Mui-disabled": {
                              color: "#C8C9DB",
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: activeStep == index ? "#686DFC" : "#C8C9DB",
                            },
                            "& .Mui-completed": { color: "white" },
                            " & .MuiStepLabel-label ": {
                              color: "white",
                            },
                          }}
                        >
                          <StepLabel
                            {...labelProps}
                            icon={
                              step.text == "Basic Details" ? (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    backgroundImage: activeStep >= index ? "linear-gradient(to  bottom, #AB82F9, #696CFF)" : "#4A4C64",
                                  }}
                                >
                                  <FeedIcons color="white" />
                                </Box>
                              ) : (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "8px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    "& .Mui-active": { backgroundColor: "red" },
                                    background: activeStep >= index ? "linear-gradient(to  bottom, #AB82F9, #696CFF)" : "#4A4C64",
                                  }}
                                >
                                  <BalanceIcon color="white" />
                                </Box>
                              )
                            }
                          >
                            {step.text}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Grid item xs={12} style={{ paddingTop: "16px" }}>
                    <Divider sx={{ backgroundColor: "#4A4C64" }} />
                  </Grid>

                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <UpdateIssueForm
                        activeStep={activeStep}
                        scrollToRef={scrollToRef}
                        handleNext={handleNext}
                        handleSkip={handleSkip}
                        handleBack={handleBack}
                        steps={steps}
                        isStepOptional={isStepOptional}
                        ref={formRef}
                      />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditIssuesPage;
