import { useCallback, useState } from "react";
import PopupModel from "../Components/Modal";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useAxios } from "../services/http.service";
import { useSelector } from "react-redux";

const useFileActions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const  {get,setBearerToken }= useAxios()
  const handleFileAction = useCallback(async (filePath, action) => {
    let url = filePath;
  
    if (!filePath) return;
  
    try {
      setBearerToken(token);
      // Fetch the new URL from the backend
      const result = await get(`getFile/${url}`);
     const newUrl= result?.data


      // Ensure the URL is properly set before proceeding
      if (!newUrl) {
        console.error("Error fetching file URL");
        return;
      }
  
      const fileName = decodeURIComponent(newUrl.split('/').pop().split('?')[0]); // Extract the file name before the query parameters
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Get the file extension
  
      // Handle view action
      if (action === "view") {
        let viewerUrl;
  
        // Check the extracted file extension to detect the file type
        if (fileExtension === "xlsx") {
          // Handle .xlsx files
          viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(newUrl)}`;
        } else if (fileExtension === "docx") {
          // Handle .docx files
          viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(newUrl)}`;
        } else {
          // Handle other file types (PDFs, images, etc.)
          viewerUrl = newUrl;
        }
  
        // Show the file in a modal
        setIsModalOpen(true);
        setModalContent(viewerUrl);
      }
  
      
      // Handle generated action (open in new tab)
      else if (action === "generated") {
        window.open(newUrl, "_blank");
      }
  
      // Handle download action
      else if (action === "download") {
        const isProduction = process.env.REACT_APP_BACKEND_URL === "https://soon.magicpleadings.ai/api/";
  
        let downloadUrl = newUrl;
        if (isProduction && !downloadUrl.startsWith("https://")) {
          downloadUrl = downloadUrl.replace("http://", "https://");
        }
  
        fetch(downloadUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/octet-stream",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            const originalFileName = filePath.split("/").pop();
            const cleanedFileName = originalFileName.replace(/^\d+-\d+-/, "");
  
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", cleanedFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) => {
            console.error("Error during file download:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching file URL or handling file action:", error);
    }
  }, []);
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const FileViewModal = () => {
    return (
      <>
        <PopupModel
          open={isModalOpen}
          styleProp={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%" },
            maxWidth: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            backgroundColor: "#262535",
            boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
            border: "1px solid #686DFC",
          }}
          handleClose={handleCloseModal}
          cards={
            <Box>
              <Box style={{ position: "relative" }}>
                <Typography
                  variant="h5"
                  sx={{ color: "#E6E7F2", fontFamily: "Public Sans ,sans-serif" }}
                >
                  View File
                </Typography>
                <Divider sx={{ backgroundColor: "#4A4C64", marginY: "10px" }} />
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-12px",
                    color: "#FFFFFF",
                    zIndex: 1000,
                  }}
                >
                  <ClearIcon />
                </IconButton>

               
                  <iframe
                    src={modalContent}
                    title="File Preview"
                    style={{ width: "100%", height: "70vh", border: "none" }}
                  />
         
              </Box>
            </Box>
          }
        />
      </>
    );
  };


  const handleDownloadZip = useCallback((files) => {
    const zip = new JSZip();
    const filesData = [...files.file1, ...files.file2]; // Corrected line
    
    const filePromises = filesData.map(async (fileUrl, index) => {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
 
      const fileName = `file${index + 1}${fileUrl.slice(fileUrl.lastIndexOf('.'))}`;
      zip.file(fileName, blob);
    });
  
    Promise.all(filePromises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((zipContent) => {
        saveAs(zipContent, 'files.zip'); 
      });
    });
  }, []);
  return { handleFileAction, FileViewModal,handleDownloadZip };
};

export default useFileActions;
