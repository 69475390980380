import { Box, Button, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { inputIssueStyles, inputIsusueStyles } from "../../pages/Issues/style";
import { theme } from "../../constant/Theme";
import LabeledTextField, { NoteInput } from "../../constant/ConstantInputField";
export const initialValues = {
  name: "",
  summaryOfLaw: "",
  caseOneCitation: "",
  factOne: "",
  lawOne: "",
  rullingOne: "",
  issueCaseFileOne: "",
  caseTwoCitation: "",
  factTwo: "",
  lawTwo: "",
  rullingTwo: "",
  issueCaseFileTwo: "",
  caseThreeCitation: "",
  factThree: "",
  lawThree: "",
  rullingThree: "",
  issueCaseFileThree: "",
};
export const validationSchemaStepOne = Yup.object({
  name: Yup.string().required("Issue name is required."),
  summaryOfLaw: Yup.string().required("Summary of law is required.").max(5000, "The summary of the law cannot exceed 5000 characters."),
});
const SUPPORTED_FORMATS = ["application/pdf"];
const FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes;
export const validationSchemaStepTwo = Yup.object({
  caseOneCitation: Yup.string().required("Case 1 citation  is required."),
  factOne: Yup.string().required("Facts is required.").max(5000, "Facts must be at least 5000 characters."),
  lawOne: Yup.string().required("Law  is required.").max(5000, "Law must be at least 5000 characters."),
  rullingOne: Yup.string().required("Ruling is required.").max(5000, "Ruling must be at least 5000 characters."),
  issueCaseFileOne: Yup.mixed()
    .nullable()
    .required(" Case file 1 is a required field.")
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value?.size || (value?.size && value?.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
const isAnyFieldFilled = (fields, context) => {
  return fields.some((field) => context.parent[field]);
};
export const validationSchemaStepThree = Yup.object().shape({
  caseTwoCitation: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["factTwo", "lawTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    }),
  factTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "lawTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    })
    .max(5000, "Facts must be at least 5000 characters."),
  lawTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    })
    .max(5000, "Law must be at least 5000 characters."),
  rullingTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "lawTwo", "issueCaseFileTwo"], this) || !!value;
    })
    .max(5000, "Rulling must be at least 5000 characters."),
  issueCaseFileTwo: Yup.mixed()
    .nullable()
    .test("is-required", "Case file 2 is a required field.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "lawTwo", "rullingTwo"], this) || !!value;
    })
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value.size || (value.size && value.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
export const validationSchemaStepFour = Yup.object().shape({
  caseThreeCitation: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["factThree", "lawThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    }),
  factThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "lawThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    })
    .max(5000, "Facts must be at least 5000 characters."),
  lawThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    })
    .max(5000, "Law must be at least 5000 characters."),
  rullingThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "lawThree", "issueCaseFileThree"], this) || !!value;
    })
    .max(5000, "Rulling must be at least 5000 characters."),
  issueCaseFileThree: Yup.mixed()
    .nullable()
    .test("is-required", "Case file 3 is a required field.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "lawThree", "rullingThree"], this) || !!value;
    })
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value.size || (value.size && value.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const IssueRenderStep = (activeStep, formik) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleUpload = (event) => {
    setSelectedFiles([...selectedFiles]);
  };
  const handleFileUploadOne = (event) => {
    formik.setFieldValue("issueCaseFileOne", event.currentTarget.files[0]);
  };
  const handleFileUploadTwo = (event) => {
    formik.setFieldValue("issueCaseFileTwo", event.currentTarget.files[0]);
  };
  const handleFileUploadThree = (event) => {
    formik.setFieldValue("issueCaseFileThree", event.currentTarget.files[0]);
  };

  const handleRemoveFileOne = () => {
    formik.setFieldValue("issueCaseFileOne", null);
  };
  const handleRemoveFileTwo = () => {
    formik.setFieldValue("issueCaseFileTwo", null);
  };
  const handleRemoveFileThree = () => {
    formik.setFieldValue("issueCaseFileThree", null);
  };

  const [charCount, setCharCounts] = useState({
    summaryOfLaw: 0,
    factOne: 0,
    lawOne: 0,
    rullingOne: 0,
    factTwo: 0,
    lawOne: 0,
    rullingTwo: 0,
    lawTwo: 0,
    factThree: 0,
    lawThree: 0,
    rullingThree: 0,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCharCounts((prevCounts) => ({
      ...prevCounts,
      [name]: value.length,
    }));
    formik.setFieldValue(name, value);
  };
  useEffect(() => {
    const updatedCharCounts = Object.keys(formik.values).reduce((counts, fieldName) => {
      if (charCount.hasOwnProperty(fieldName)) {
        counts[fieldName] = formik.values[fieldName]?.length || 0;
      }
      return counts;
    }, {});
    setCharCounts(updatedCharCounts);
  }, [formik.values]);

  switch (activeStep) {
    case 0:
      return (
        <>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <LabeledTextField formik={formik} name="name" label="Issue name" placeholder="Enter Issue name" required={true} inputStyles={inputIssueStyles?.formControl(formik)} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "4px",
                  color: theme?.palette?.secondary?.main, // Dynamic label color
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Summary of law *
              </Typography>

              <TextField
                fullWidth
                multiline
                required
                rows={6}
                name="summaryOfLaw"
                placeholder="Enter Summary of law"
                variant="outlined"
                value={formik.values.summaryOfLaw}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                error={formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw)}
                helperText={formik.touched.summaryOfLaw && formik.errors.summaryOfLaw}
                sx={inputIssueStyles?.summaryLaw(formik)}
                InputLabelProps={{
                  style: {
                    color: formik.touched?.summaryOfLaw && Boolean(formik.errors?.summaryOfLaw) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                    fontFamily: theme?.typography?.fontFamily,
                  },
                }}
                InputProps={{
                  style: {
                    color: theme?.palette?.primary?.main,
                    fontFamily: theme?.typography?.fontFamily,
                  },
                }}
              />

              <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right" }}>
                {charCount?.summaryOfLaw} / 5000
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    case 1:
      return (
        <>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Case 1 Citation *
          </Typography>

          <TextField
            fullWidth
            required
            size="small"
            placeholder="Case 1 Citation"
            name="caseOneCitation"
            variant="outlined"
            value={formik.values?.caseOneCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation)}
            helperText={formik.touched?.caseOneCitation && formik.errors?.caseOneCitation}
            sx={inputIssueStyles?.caseOfCitationOne(formik)}
            InputLabelProps={{
              style: { color: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main },
            }}
            InputProps={{
              style: {
                color: theme?.palette?.primary?.main,
              },
            }}
          />

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Facts *
          </Typography>

          <TextField
            fullWidth
            required
            placeholder="Facts"
            multiline
            rows={6}
            name="factOne"
            variant="outlined"
            value={formik.values?.factOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factOne && Boolean(formik.errors?.factOne)}
            helperText={formik.touched?.factOne && formik.errors?.factOne}
            sx={inputIssueStyles?.factOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factOne} / 5000
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Law *
          </Typography>

          <TextField
            fullWidth
            required
            placeholder="Law"
            name="lawOne"
            multiline
            rows={4}
            variant="outlined"
            value={formik.values?.lawOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawOne && Boolean(formik.errors?.lawOne)}
            helperText={formik.touched?.lawOne && formik.errors?.lawOne}
            sx={inputIssueStyles?.lawOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                // height: "120px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawOne} / 5000
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Ruling *
          </Typography>

          <TextField
            fullWidth
            required
            placeholder="Ruling"
            name="rullingOne"
            multiline
            rows={6}
            variant="outlined"
            value={formik.values?.rullingOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingOne && Boolean(formik.errors?.rullingOne)}
            helperText={formik.touched?.rullingOne && formik.errors?.rullingOne}
            sx={inputIssueStyles?.rullingOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingOne} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: theme?.palette?.secondary?.main,
                  cursor: "pointer !important",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Upload Case 1 *
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                }}
              >
                {" "}
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.issueCaseFileOne ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf , .doc"
                      onChange={(e) => handleFileUploadOne(e)}
                      error={formik.touched.issueCaseFileOne && Boolean(formik?.errors?.issueCaseFileOne)}
                      helperText={formik?.touched?.issueCaseFileOne && formik?.errors?.issueCaseFileOne}
                    />
                  </Button>
                  <Typography color={theme?.palette?.secondary?.main} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileOne?.name ? formik?.values?.issueCaseFileOne?.name : formik?.values?.issueCaseFileOne ? formik?.values?.issueCaseFileOne : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileOne && (
                    <Button
                      onClick={handleRemoveFileOne}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>
              {formik?.touched?.issueCaseFileOne && formik?.errors?.issueCaseFileOne && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileOne}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    case 2:
      return (
        <>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main,
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Case 2 Citation
          </Typography>

          <TextField
            fullWidth
            size="small"
            placeholder="Case 2 Citation"
            name="caseTwoCitation"
            variant="outlined"
            value={formik.values?.caseTwoCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation)}
            helperText={formik?.touched?.caseTwoCitation && formik?.errors?.caseTwoCitation}
            sx={inputIssueStyles?.caseTwoCitation(formik)}
            InputLabelProps={{
              style: {
                color: formik.touched?.caseTwoCitation && Boolean(formik.errors?.caseTwoCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
            InputProps={{
              style: {
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Facts
          </Typography>

          <TextField
            fullWidth
            placeholder="Facts"
            multiline
            rows={6}
            name="factTwo"
            variant="outlined"
            value={formik.values?.factTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factTwo && Boolean(formik.errors?.factTwo)}
            helperText={formik.touched?.factTwo && formik.errors?.factTwo}
            sx={inputIssueStyles?.factOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factTwo} / 5000
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Law
          </Typography>

          <TextField
            fullWidth
            placeholder="Law"
            multiline
            rows={4}
            name="lawTwo"
            variant="outlined"
            value={formik.values?.lawTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawTwo && Boolean(formik.errors?.lawTwo)}
            helperText={formik.touched?.lawTwo && formik.errors?.lawTwo}
            sx={inputIssueStyles?.lawOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                height: "120px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawTwo} / 5000
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Ruling
          </Typography>

          <TextField
            fullWidth
            placeholder="Ruling"
            multiline
            rows={6}
            name="rullingTwo"
            variant="outlined"
            value={formik.values?.rullingTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingTwo && Boolean(formik.errors?.rullingTwo)}
            helperText={formik.touched?.rullingTwo && formik.errors?.rullingTwo}
            sx={inputIssueStyles?.rullingOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                height: "200px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingTwo} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: theme?.palette?.secondary?.main,
                  fontFamily: theme?.typography?.fontFamily,

                  // fontSize: "12px",
                  cursor: "pointer !important",
                }}
              >
                Upload Case 2
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.medicalRecordFile ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf,.doc"
                      onChange={(e) => handleFileUploadTwo(e)}
                      error={formik.touched.issueCaseFileTwo && Boolean(formik?.errors?.issueCaseFileTwo)}
                      helperText={formik?.touched?.issueCaseFileTwo && formik?.errors?.issueCaseFileTwo}
                    />
                  </Button>
                  <Typography color={theme?.palette?.secondary?.main} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileTwo?.name ? formik?.values?.issueCaseFileTwo?.name : formik?.values?.issueCaseFileTwo ? formik?.values?.issueCaseFileTwo : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileTwo && (
                    <Button
                      onClick={handleRemoveFileTwo}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>
              {formik?.touched?.issueCaseFileTwo && formik?.errors?.issueCaseFileTwo && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileTwo}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    case 3:
      return (
        <>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color                  fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Case 3 Citation
          </Typography>

          <TextField
            fullWidth
            size="small"
            placeholder="Case 3 Citation"
            name="caseThreeCitation"
            variant="outlined"
            value={formik.values?.caseThreeCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation)}
            helperText={formik?.touched?.caseThreeCitation && formik?.errors?.caseThreeCitation}
            sx={inputIssueStyles?.caseThreeCitation(formik)}
            InputLabelProps={{
              style: {
                color: formik.touched?.caseThreeCitation && Boolean(formik.errors?.caseThreeCitation) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.secondary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
            InputProps={{
              style: {
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Facts
          </Typography>

          <TextField
            fullWidth
            placeholder="Facts"
            multiline
            rows={6}
            name="factThree"
            variant="outlined"
            value={formik.values?.factThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factThree && Boolean(formik.errors?.factThree)}
            helperText={formik.touched?.factThree && formik.errors?.factThree}
            sx={inputIssueStyles?.factOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factThree} / 5000
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
            }}
          >
            Law
          </Typography>

          <TextField
            fullWidth
            placeholder="Law"
            multiline
            rows={4}
            name="lawThree"
            variant="outlined"
            value={formik.values?.lawThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawThree && Boolean(formik.errors?.lawThree)}
            helperText={formik.touched?.lawThree && formik.errors?.lawThree}
            sx={inputIssueStyles?.lawOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main },
            }}
            InputProps={{
              style: {
                height: "120px",
                color: theme?.palette?.secondary?.main,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawThree} / 5000
          </Typography>

          <Typography
            variant="body1"
            sx={{
              marginBottom: "4px",
              color: theme?.palette?.secondary?.main, // Dynamic label color
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Ruling
          </Typography>

          <TextField
            fullWidth
            placeholder="Ruling"
            multiline
            rows={6}
            name="rullingThree"
            variant="outlined"
            value={formik.values?.rullingThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingThree && Boolean(formik.errors?.rullingThree)}
            helperText={formik.touched?.rullingThree && formik.errors?.rullingThree}
            sx={inputIssueStyles?.rullingOne}
            InputLabelProps={{
              style: { color: theme?.palette?.secondary?.main, fontFamily: theme?.typography?.fontFamily },
            }}
            InputProps={{
              style: {
                height: "200px",
                color: theme?.palette?.primary?.main,
                fontFamily: theme?.typography?.fontFamily,
              },
            }}
          />
          <Typography variant="caption" color={theme?.palette?.primary?.main} sx={{ display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingThree} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: "#C8C9DB",
                  // fontSize: "12px",
                  cursor: "pointer !important",
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                Upload Case 3
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                }}
              >
                {" "}
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.medicalRecordFile ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf,.doc"
                      onChange={(e) => handleFileUploadThree(e)}
                      error={formik.touched.issueCaseFileThree && Boolean(formik?.errors?.issueCaseFileThree)}
                      helperText={formik?.touched?.issueCaseFileThree && formik?.errors?.issueCaseFileThree}
                    />
                  </Button>
                  <Typography sx={{ color: "#C8C9DB", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileThree?.name ? formik?.values?.issueCaseFileThree?.name : formik?.values?.issueCaseFileThree ? formik?.values?.issueCaseFileThree : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileThree && (
                    <Button
                      onClick={handleRemoveFileThree}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>

              {formik?.touched?.issueCaseFileThree && formik?.errors?.issueCaseFileThree && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileThree}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
